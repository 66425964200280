﻿/* ==============================
   2 Global styles
   ============================== */

.clearfix {
    clear: both;
}

.m0 {
    margin: 0 !important;
}

.mt30 {
    margin-top: 30px;
}

.p0 {
    padding: 0 !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-40 {
    font-size: 40px !important;
}

.fs-32 {
    font-size: 32px !important;
}

.fs-28 {
    font-size: 28px !important;
}

.fs-h1 {
    font-size: 36px !important;
}

.fs-h2 {
    font-size: 30px !important;
}

.fs-h3 {
    font-size: 24px !important;
}

.fs-h4 {
    font-size: 18px !important;
}

.fs-h5 {
    font-size: 14px !important;
}

.fs-h6 {
    font-size: 13px !important;
}

.fw-bold {
    font-weight: 600;
}

.fw-bolder {
    font-weight: 700;
}

.position-initial {
    position: initial;
}

.text-intro {
    font-size: 22px;
    max-width: 990px;
}

.condensed {
    font-family: $open-sans-cond;
    letter-spacing: .3px;
}

.text-tertiary {
    color: $tertiary;
}

/* Animations */

.animate-3 {
    transition: all .3s ease;
}

.animate-5 {
    transition: all .5s ease;
}

.animate-7 {
    transition: all .7s ease;
}

.animate-3-all * {
    transition: all .3s ease;
}

.animate-5-all * {
    transition: all .5s ease;
}

.animate-7-all * {
    transition: all .7s ease;
}

.overlay-fade-on-hover {
    .image-callout-content {
        background-color: rgba(39, 39, 39, 0.4);
        transition: background-color 0.15s linear;
    }

    .image-callout {
        &:hover {
            > .image-callout-content {
                background-color: transparent;
            }
        }
    }
}

/* Custom Animations */

@keyframes calloutSlideUp {
    0% {
        transform: translate3d(0,11%,0); // translate3d(tx, ty, tz)
    }

    to {
        transform: translateZ(0);
    }
}

@keyframes calloutSlideDown {
    0% {
        transform: translateZ(0);
    }

    to {
        transform: translate3d(0,11%,0); // translate3d(tx, ty, tz)
    }
}