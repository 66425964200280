﻿// Use this file to overwrite the basic Bootstrap variables and add custom variables
// Just copy a variable from node_modules/bootstrap/scss/_variables.scss and edit the value.

$images: '../images';

// Colors
$blue: #3090C0;
$red: #e03127;
$cyan: #28a6b6;
$navy: #13314c;

$primary: $red;
$secondary: $blue;
$tertiary: $cyan;

$dark: #333;
$medium: #4a4a4a;
$light: #c7c6c6;
$soft: #f1f1f1;

// Typography
$open-sans: 'Open Sans', sans-serif;
$open-sans-cond: 'PT Sans Narrow',sans-serif;

$font-family-base: $open-sans;
$headings-font-family: $font-family-base;
$heading-font-secondary: $open-sans-cond;

$body-color: $dark;

$line-height-base: 1.8;
$line-height-sm: 1.4;
$line-height-lg: 2.2;

$headings-color: $dark;
$headings-line-height: $line-height-sm;
$headings-font-weight: 700;

// Border
$border-color: $light;

$border-radius: 5px;
$border-width: 1px;

$border: $border-width solid $border-color;

// Button
$btn-transition: color .3s ease, background-color .3s ease, border-color .3s ease, box-shadow .3s ease !default;


