﻿/* ==============================
  12. Our Client styles
============================== */

.our-client {
    background: #fff;
    position: relative;
}

.client-carousel .item img {
    opacity: 0.5 !important;
    border: 1px solid #f0f0f0;
    transition: all .3s ease;

    &:hover {
        opacity: 1 !important;
    }
}
