/***************************************************************************************************************
||||||||||||||||||||||||||||       MASTER STYLESHEET FOR INDUSTRIAL       ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
||||||||||||||||||||||||||||              TABLE OF CONTENT                  ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************
****************************************************************************************************************
Imported styles
Global styles
header top styles
Header Menu styles
Banner styles
Welcome to industrial styles
Our Services styles 
Our Services natural resource styles
Our Project Slider styles
Home styles
Our Client styles
Indurial Solution styles
Footer styles
Inner Banner styles
About Tab Box styles
Team styles
Testimonial Area
Services Page styles
Side Bar Widget styles
Single service page styles
Core Projects page styles
News page styles
Page Navigation styles
News Details styles
News Details Comments
Contact us styles
Testimonials page styles
Request a quote Page styles
Project details page styles
Newsletter
TT-GALLERY-1
LIGHTBOX
****************************************************************************************************************
||||||||||||||||||||||||||||            End TABLE OF CONTENT                ||||||||||||||||||||||||||||||||||||
****************************************************************************************************************/
/* ==============================
   Imported styles
   ============================== */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300italic,700);
@import url("https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700");


// Config
@import "config/variables";

/* Bootstrap 5.0 breakpoint and utilities only */
@import "assets/bootstrap";

/* Bootstrap 3.3.6 */
@import "assets/bootstrap-theme.min";

/* Global */
@import "global/_base.scss";
@import "global/_buttons.scss";
@import "global/_list.scss";
@import "global/_media.scss";
@import "global/_typography.scss";

/* Layout */
@import "layout/_layout.scss";

/* Components */
@import "components/_accordion.scss";
@import "components/_banner_inner.scss";
@import "components/_banner.scss";
@import "components/_footer.scss";
@import "components/_header.scss";
@import "components/_lightbox_gallery.scss";
@import "components/_menu_main.scss";
@import "components/_newsletter.scss";
@import "components/_page_navigation.scss";
@import "components/_sidebar.scss";
@import "components/_testimonial.scss";
@import "components/_widgets.scss";

/* Theme */
@import "theme/_home_two.scss";
@import "theme/_home.scss";
@import "theme/_our_client.scss";
@import "theme/_our_project.scss";
@import "theme/_our_services.scss";
@import "theme/_our_solutions.scss";
@import "theme/_page_about.scss";
@import "theme/_page_cart.scss";
@import "theme/_page_checkout.scss";
@import "theme/_page_contact.scss";
@import "theme/_page_fqa.scss";
@import "theme/_page_news.scss";
@import "theme/_page_product.scss";
@import "theme/_page_projects.scss";
@import "theme/_page_request_quote.scss";
@import "theme/_page_service.scss";
@import "theme/_page_shop.scss";
@import "theme/_page_solution.scss";
@import "theme/_page_testimonial.scss";
@import "theme/_ragion_customer_support.scss";
@import "theme/_region_control_in_complience.scss";
@import "theme/_region_get_in_touch.scss";
@import "theme/_region_latest_news.scss";
@import "theme/_region_looking_adequate.scss";
@import "theme/_region_our_client.scss";
@import "theme/_section_blog.scss";
@import "theme/_tab_box_about.scss";
@import "theme/_tab_box_service.scss";
@import "theme/_team.scss";
@import "theme/_welcome_industrial.scss";

/* Icons */
@import "assets/font-awesome.min";
@import "assets/strock-icon";

