﻿/* ==============================
  29. Request a quote Page styles
============================== */

.section_header-r {
    position: relative;
    padding: 0;
    margin: 0 0 1em;

    h2 {
        font: 700 2.2em/1.2em PT Sans Narrow,sans-serif;
        color: #000;
        text-transform: uppercase;
        position: relative;
        padding-bottom: 20px;
        letter-spacing: .3px;
        margin: 0 0 1em;

        &:before {
            content: "";
            width: 55px;
            height: 3px;
            display: inline-block;
            background: #298fc2;
            position: absolute;
            bottom: 0;
        }
    }
}

.req-page-area {
    background: #f7f7f7;
    border: 1px solid #eee;
    overflow: hidden;
    padding: 3em 1em 0;
    position: relative;
}

.req-page span {
    display: block;
    color: #404040;
    font: 600 1em/1.2em Open Sans,sans-serif;
    margin: 0 0 .6em;
}

.req-page-area {
    .img-responsive {
        display: inline-block;
    }

    form .form-control {
        margin-bottom: 20px;
        border-radius: 0;
        font: 15px/31px Open Sans,sans-serif;
        color: #959595;
        padding: 11px 20px;
        border: 1px solid #e1e1e1;
        height: auto;
        box-shadow: none;
    }
}

.pad-left {
    margin: 0 0 5em 10px;
}

.req-img {
    position: absolute;
    bottom: 0;
    right: 0;
}

.req-page-area {
    form {
        button {
            border: 1px solid #f6ba18;
            text-transform: uppercase;
            letter-spacing: 1px;
            font: 600 1.2em/2.1em Open Sans,sans-serif;
            padding: 7px 22px;
            color: #19242a;
            margin-top: 10px;
            border-radius: 5px;
            letter-spacing: 3px;

            &:hover {
                color: #000;
                border-color: #bb793c;
            }
        }

        .form-control {
            &:focus, &:hover {
                border: 1px solid #d6d6d6;
            }

            &:focus {
                box-shadow: 0 0 6px 0 rgba(215, 149, 62, 0.57);
            }
        }
    }

    .form-control.error {
        margin-bottom: 0;
    }

    form {
        .form-control.error + .error {
            font: 13px/24px Open Sans,sans-serif;
            margin-bottom: 20px;
            margin-top: 5px;
            color: red;
            font-weight: 400;
        }

        textarea.form-control {
            margin-bottom: 32px;
            resize: none;
            padding: 15px 20px;
            height: 203px;
        }
    }

    span {
        color: #404040;
        font: 1.1em/1.1em Open Sans,sans-serif;
        display: block;
        padding: 0 0 .5em;
    }

    .input_form {
        #success {
            color: #7cfc00;
        }

        #error {
            color: #ff4500;
            position: absolute;
            top: -15px;
            display: block;
            right: 0;
            left: 0;
            bottom: -15px;
            background: rgba(0, 0, 0, 0.5);
            display: none;
        }

        #success {
            position: absolute;
            top: -15px;
            display: block;
            right: 0;
            left: 0;
            bottom: -15px;
            background: rgba(0, 0, 0, 0.5);
            display: none;
        }
    }
}
