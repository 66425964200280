﻿/* ==============================
  18. Testimonial Area styles
============================== */

.testimonial-area {
    background: #f5f4f4;
}

.testimonial-sliders {
    .owl-nav {
        position: absolute;
        right: 0;
        top: -80px;
    }

    &.owl-theme .owl-controls .owl-nav [class*=owl-] {
        background: transparent;
        border-radius: 0;
        margin: 0;
        padding: 0;
    }

    &.owl-carousel .owl-controls .owl-nav {
        .owl-next, .owl-prev {
            background: #61bac8;
            width: 33px;
            text-align: center;
            font-size: 20px;
            line-height: 33px;
            color: #fff;
            float: left;
            transition: all .3s linear 0s;
        }
    }

    &.owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
        background: #329cad;
        color: #fff;
        text-decoration: none;
    }

    &.owl-carousel .owl-controls .owl-nav .owl-prev {
        margin-right: 5px;
    }
}

@media (max-width: 479px) {
    .testimonial-sliders .owl-nav {
        top: 0;
    }
}

.testimonial {
    position: relative;

    + .testimonial {
        margin-top: 30px;
    }

    .media-left {
        position: relative;
        padding-right: 0;

        a {
            display: block;
            width: 170px;
            height: 185px;
            line-height: 185px;
        }

        img {
            width: 140px;
            height: 140px;
            border-radius: 50%;
        }

        &:before {
            content: "";
            position: absolute;
            right: -2px;
            top: 19.2%;
            z-index: 12;
            background: url(../images/testimonial/arrow.png) no-repeat 0 0;
            width: 15px;
            height: 27px;
        }
    }

    .media-body {
        vertical-align: middle;
        padding: 0 30px;
        border: 2px solid #e4e4e4;
        background: #fff;

        p {
            font: 400 14px/26px Open Sans,sans-serif;
            letter-spacing: .3px;
            color: #898989;
            margin-bottom: 5px;

            span {
                display: inline-block;
                background: url(../images/testimonial/quote.jpg) no-repeat scroll 50%;
                width: 21px;
                height: 19px;
                margin-right: 16px;
            }
        }

        a {
            font: 300 14px/26px Open Sans,sans-serif;
            letter-spacing: .3px;
            color: #c89551;
            font-style: italic;
            text-align: right;
            display: block;
        }
    }
}

@media (max-width: 479px) {
    .testimonial {
        display: block;
        background: none;
    }
}

@media (max-width: 479px) {
    .testimonial .media-left {
        display: block;
        text-align: center;
    }
}

@media (max-width: 479px) {
    .testimonial .media-left a {
        margin: 0 auto;
    }
}

@media (max-width: 479px) {
    .testimonial .media-left:before {
        display: none;
    }
}

@media (max-width: 479px) {
    .testimonial .media-body {
        background: #fff;
        text-align: left;
        padding: 30px;
    }
}

@media (max-width: 767px) {
    .testimonial .media-body p {
        line-height: 22px;
        font-weight: 300;
        margin-bottom: 0;
    }
}

.testimonial-col {
    padding: 25px 15px;
}

.testimonial-bg {
    padding: 55px 0;
}

.testimonial-sliders {
    padding-top: 0;
}
