﻿/* ==============================
  4. Header Menu styles
============================== */

/* Home  Navigation */

.main_menu {
    .logo {
        img {
            transition: width .7s ease;
        }
    }

    &.stricky-fixed {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 9999;
        background: white;
        box-shadow: 1px 1px 3px rgba(0,0,0,0.2);

        .logo {
            img {
                width: 50px;
            }
        }

        .nav-t-footer {
            ul.nav > li {
                > a {
                    @include media-breakpoint-up(lg) {
                        padding: 20.5px 1.1em 38.5px
                    }
                }
            }
        }
    }
}

.nav-t-header {
    display: none;
    padding: 12px 0;
    float: right;

    button {
        background: transparent;
        border: none;
        outline: none;
        color: #fff;
        font-size: 25px;
        border-radius: 5px;
    }
}

.nav-home-three {
    background: #0f263a;
    position: relative;
}

.nav-home-four {
    padding: 15px 0 0;
    background: white;
}

.nav-home-three .col-md-3 {
    display: none;
}

.nav-home-two {
    background: transparent;

    .col-md-3 {
        display: none;
    }
}

.pad-logo {
    padding-top: 1.8em;
    padding-bottom: 1.83em;
}

.touch_top_pad {
    padding-top: .7em;
    padding-bottom: 0;
}


.nav-header {
    display: none;
    padding: 12px 20px;
}

.nav-t-footer {
    display: block;

    ul li {
        &.active > a, a:hover, a:focus {
            background-color: transparent;
            color: #298fc2 !important;
        }
    }

    ul.nav {

        > li {
            position: inherit;
            display: inline-block;

            &.active a, a:hover, a:focus {
                background-color: transparent;
                color: #298fc2;
            }

            &:hover > a:after {
                position: absolute;
                left: 44.2%;
                bottom: 0;
                content: "";
                width: 0;
                height: 0;
                margin-top: 8px;
                margin-left: -14px;
                border-right: 12px solid transparent;
                border-left: 12px solid transparent;
                border-bottom: 12px solid $navy;
            }

            &:hover > ul.submenu {
                visibility: visible;
                opacity: 1;
            }

            > {
                a {
                    display: block;
                    font: 600 1.20em/1em Open Sans Condensed, sans-serif;
                    color: #000;
                    text-transform: uppercase !important;
                    padding: 1.2em 1.2em;
                    transition: all .3s ease;

                    @include media-breakpoint-up(md) {
                        padding: 35.5px 1.1em 55.5px;
                    }

                    button {
                        display: none;
                    }
                }
            }

            ul.submenu {
                top: 100%;
                left: 0;

                li {

                    a {
                        display: inline-block;
                        color: $dark;
                        margin: 2px 0;
                    }
                }
            }

            > ul.submenu {
                position: absolute;
                width: 100%;
                background: rgba(255, 255,255, 0.95);
                text-align: center;
                padding: 20px 20px 35px;
                opacity: 0;
                visibility: hidden;
                z-index: 99999;
                transition: all .3s ease;
                border-top: 5px solid $navy;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);

                .submenu .submenu {
                    display: none;
                }

                > li {
                    display: inline-block;
                    vertical-align: top;
                    max-width: 350px;
                    padding: 0 25px;
                    text-align: left;

                    > a {
                        width: 100%;
                        font-size: 16px;
                        font-weight: 600;
                        text-transform: uppercase;
                        padding: 6px 0;
                        margin: 6px 0;
                        border-bottom: 2px solid #ddd;
                    }
                }
            }
        }
    }
}

.touch_top ul .item .media .blue-color a i {
    color: #1fa6c8;
}

@-webkit-keyframes menu_sticky {
    0% {
        margin-top: -100px;
    }

    50% {
        margin-top: -90px;
    }

    to {
        margin-top: 0;
    }
}

@keyframes menu_sticky {
    0% {
        margin-top: -100px;
    }

    50% {
        margin-top: -90px;
    }

    to {
        margin-top: 0;
    }
}

.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    animation-name: menu_sticky;
    animation-duration: .6s;
    animation-timing-function: ease-out;
    box-shadow: 0 13px 30px -12px rgba(0, 0, 0, 0.75);
}

.widget-t-search {
    float: right;
    padding: 10px 0;
}

.widget-t .search-form .form-control {
    height: 40px;
    border-radius: 0;
    border: none;
    font: 1em/1em Open Sans,sans-serif;
    padding: 0 18px;
    box-shadow: none;
    color: silver;
    z-index: 0;
    background: #081e2f;
}

.widget-t-inner {
    border: 1px solid #1a2e40;
    width: 180px;
}

.widget-t .search-form .form-control + .input-group-addon {
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: #081e2f;

    button {
        border: 0;
        background: #081e2f;
        color: #bcbcbd;
        font-size: 1.2em;
        line-height: 2.1em;
        padding-right: 15px;
    }
}