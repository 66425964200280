﻿.btn {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 6px 30px;
    background: #298fc2;
    border-radius: $border-radius;

    &:focus, &:hover {
        text-decoration: none;
        outline: none;
    }

    &0 {
        background: #53565a;
        margin: 0 20px 0 0;
    }

    &2 {
        color: #000;
        background: #f8d24b;
    }

    &3 {
        background: rgba(#000000, 0.4);
        border: 1px solid white;

        &:hover {
            background: white;
        }
    }
}

button {
    &:focus, &:hover {
        text-decoration: none;
        outline: none;
    }
}

.read-more {
    display: inline-block;
    margin-top: 25px;
    margin-bottom: 15px;
}


.submit {
    color: #fff;
    text-transform: uppercase;
    margin-top: 8px;
    text-shadow: none;
    position: relative;
    letter-spacing: .4px;
    transition: all .3s linear 0s;
    z-index: 1;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #298fc2;
        top: 0;
        left: 0;
        right: 0;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: all .3s linear 0s;
        z-index: -1;
    }

    &:focus, &:hover {
        color: #fff;
        border-color: #bb793c;

        &:before, &:before {
            transform: scaleX(1);
        }
    }
}