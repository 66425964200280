﻿/* ==============================
  40. our clients & Get in touch  
============================== */

.our-t-client {
    position: relative;

    p {
        color: #898989;
        font: 400 1em/1.8em Open Sans,sans-serif;
        margin: 1em 0 2em;
        padding: 0;
    }

    ul {
        padding: 0;
        margin: 0;
        overflow: hidden;

        li {
            margin: 0 1em 1.2em 0;
            /*display: inline-block;*/
            float: left;

            &:last-child {
                margin: 0;
            }

            img {
                border: 1px solid #ebebeb;
                transition: all 1s ease;

                &:hover {
                    border: 1px solid #103d68;
                    border-radius: 100%;
                }
            }
        }
    }
}
