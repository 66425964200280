﻿/* ==============================
  14. Footer styles
============================== */

footer {
    background: #19242a;
    font-size: 14px;
    line-height: 1.4;
    color: #627279;
    padding: 59px 0;
    min-height: 408px;

    a {
        color: #627279;
        margin-top: 1.4em;

        &:hover {
            color: #298FC2 !important;
        }
    }

    p {
        margin-top: 2em;
    }

    .widget-links {
        padding: 0 50px;
    }
}

.widget.about-us-widget {

    a {
        color: #1FB5C7;
    }

    ul {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            list-style: none;
            padding: 10px 9px;

            &:first-child {
                padding-left: 0;
            }

            a {
                padding: 0;
                color: #7c7c7c;

                &:hover {
                    background: none;
                    color: #11adc6;
                }

                i {
                    font-size: 1.5em;
                }
            }
        }
    }
}

.get-in-touch, .widget-contact, .widget-links {
    h4 {
        font-size: 20px;
        color: #fff;
        margin-top: 0;
        margin-bottom: 24px;

        &::after {
            content: "";
            width: 40px;
            height: 2px;
            background: #0094b3;
            display: block;
            margin-top: 13px;
        }
    }
}

.widget-contact-list ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        a {
            color: #627279;
            text-transform: capitalize;
            line-height: 39px;
        }

        i {
            color: #298fc2;
            float: left;
            font-size: 25px;
            width: 34px;
            padding-top: 13px;
            line-height: 18px;
        }
    }
}

.widget.widget-contact ul li {

    .fleft {
        float: left;
        width: calc(100% - 34px);
        padding: 1.1em 0 0;
    }

    i {
        &.fa-map-marker {
            padding-top: 22px;
        }

        &.fa-phone {
            font-size: 21px;
            line-height: 41px;
        }

        &.fa-envelope-o {
            font-size: 18px;
            line-height: 41px;
        }

        &.icon-Timer {
            font-size: 20px;
            line-height: 35px;
        }
    }
}

.get-in-touch {
    position: relative;

    ul li {
        list-style: none;
        display: inline-block;
        float: left;

        img {
            margin: 0 3px 3px 0;
            width: 84px;
            height: 68px;
        }
    }
}

.footer-bottom {
    font-size: 14px;
    line-height: 1;
    color: #555759;
    padding: 33px 0;
    background: #141d22;

    p {
        color: #555759;
        margin: 0;
    }

    a {
        color: #454d54;

        &:hover {
            color: #f6ba18;
        }
    }
}

.sticky-contact {
    left: auto;
    right: 25px;
    bottom: 25px;
    padding: 5px 20px;
}
