﻿/* ==============================
  Get in touch  
============================== */

.get-t-touch {
    border: 1px solid #ebebeb;
    margin-top: 1.6em;
    position: relative;
    overflow: hidden;
}

.get-t-touch-inner {
    position: relative;
    overflow: hidden;
    border: 9px solid #f7f7f9;
    padding: 2em 1.5em;
}

.get-t-touch {
    .input_form form .form-control {
        margin-bottom: 20px;
        border-radius: 0;
        font: 1em/1em Open Sans,sans-serif;
        color: #b7b6b6;
        padding: 11px 20px;
        border: 1px solid #eaeaea;
        height: auto;
        box-shadow: none;
    }

    .select-menu + {
        .ui-selectmenu-button {
            width: 100% !important;
            border-radius: 0;
            padding: 0 40px 0 20px;
            border: 1px solid #eaeaea;
            height: 42px;
            background: #fff;
            outline: none;
            color: #b7b6b6;
            margin-bottom: 20px;

            .ui-selectmenu-text {
                padding: 0;
                font: .9em/1em Open Sans,sans-serif;
                line-height: 42px;
            }

            .ui-icon.ui-icon-triangle-1-s {
                background: url(../images/select-menu-arrow1.png) 0 0 no-repeat;
                width: 16px;
                height: 8px;
                margin-top: -4px;
                margin-right: 4px;
            }
        }

        .ui-selectmenu-menu .ui-menu-item {
            background: #2d6293;
            border-color: #00acd9;
            font-style: normal;
        }
    }

    .submit-n-now {
        position: relative;

        button {
            background: #53565a;
            font: 600 1.08em/1em PT Sans Narrow,sans-serif;
            display: inline-block;
            padding: 1em 1.52em;
            border-radius: 4px;
            border: 1px solid #53565a;
            margin: 0;

            &:hover {
                border-radius: 4px;
                border: 1px solid #53cff5;
                background: #53cff5;
            }

            i {
                margin-left: 14px;
            }
        }
    }
}

.indurial-t-solution {
    background: #103d68;
}

.contactus-button2 {
    position: relative;
    display: inline-block;
    margin-left: 4em;

    a {
        background: #298fc2;
        font: 600 1.08em/1em PT Sans Narrow,sans-serif;
        padding: .8em 1.6em;
        display: inline-block;

        i {
            margin-left: 10px;
        }
    }

    submit {
        &:hover {
            border-color: #53cff5;
        }

        &:focus, &:hover {
            color: #fff;
        }
    }
}

.contactus-button3 {
    position: relative;
    display: inline-block;
    margin-left: 0;

    a {
        background: #298fc2;
        font: 600 1.08em/1em PT Sans Narrow,sans-serif;
        padding: .8em 1.6em;
        display: inline-block;

        i {
            margin-left: 0;
        }
    }

    submit {
        &:hover {
            border-color: #53cff5;
        }

        &:focus, &:hover {
            color: #fff;
        }
    }
}

.footer-bg {
    background: #091723;
}

.footer-t-bottom {
    background: #06121c;
}

.logo-wrapper {
    text-align: left;
    min-height: 106px;

    img {
        display: inline-block;
    }
}

.sec-title h2 {
    margin-bottom: 1em;
}
