﻿/* ==============================
  Our Services natural resource styles
============================== */

.fluid-service-area {
    display: flex;
    overflow: hidden;
    background: #123450;

    .work-image-ser {
        width: calc(50% - 115px);
        float: left;
        text-align: right;
        display: block;
        position: relative;
    }

    .service-promo {
        width: calc(50% + 115px);
        float: left;
        position: relative;
        padding-left: 30px;
        background: #123450;

        ul .item {
            text-align: left;
            padding: 0 0 4.1em;
        }
    }
}

@media (max-width: 991px) {
    .fluid-service-area {
        display: block;
    }
}

@media (max-width: 991px) {
    .fluid-service-area .work-image-ser {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 991px) {
    .fluid-service-area .work-image-ser img {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .fluid-service-area .service-promo {
        width: 100%;
        padding-bottom: 30px;
        padding: 15px;
    }
}

.service-content {
    position: relative;
    z-index: 2;
    padding: 4em 4em 0;

    .media-left {
        vertical-align: middle;
        padding-right: 4em;
    }

    ul .item .media .media-body {
        text-align: left;
        font: 1em/1em Open Sans,sans-serif;
        color: #898989;
        letter-spacing: .3px;
        padding: 0 17em 0 0;

        h4 {
            font: 600 1.3em/1em Open Sans,sans-serif;
            text-transform: uppercase;
            color: #fff;
        }

        p {
            color: #fff;
            font: 1em/1.5em Open Sans,sans-serif;
        }
    }
}

.nau-head {
    position: absolute;
    background: #ffa10d;
    width: 18em;
    bottom: 1em;
    text-align: left;
    font-family: PT Sans Narrow,sans-serif;
    padding: 1em 0 0 2em;
    right: 0;

    p {
        font: 600 1.3em/1em PT Sans Narrow,sans-serif;
        text-transform: uppercase;

        span {
            display: block;
            font-size: .7em;
            font-weight: 400;
        }
    }
}


/* ==============================
  Different Services
============================== */

.diff-offer-wrapper {
    background: #f5f4f4;
    position: relative;
    padding-bottom: 2.5em;
}

.diff-offer {
    position: relative;
    padding-top: 5.5em;
    padding-bottom: 5.5em;
    overflow: hidden;

    ul li {
        float: left;
    }
}

.we-offer-cont {
    width: 32%;

    h2 {
        margin: 0;
        padding: 0 .8em 0 0;
        text-align: right;
        font: 600 2.4em/1em PT Sans Narrow,sans-serif;
        color: #323232;
        text-transform: uppercase;

        span {
            display: block;
        }
    }
}

.we-offer-cont2 {
    width: 68%;
    border-left: 3px solid #298fc2;

    p {
        font: 400 1.2em/1.3em Open Sans,sans-serif;
        margin: 0;
        padding: 0 0 0 2em;
        font-style: italic;
        color: #6c6c6c;
    }
}