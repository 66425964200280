﻿.accrodion {
    border: 1px solid #d7d7d7;
    background: #fff;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    &.active .accrodion-title {
        background: #019bb3;

        &:after, h4 {
            color: #fff;
        }

        &:after {
            content: "-";
            font-size: 40px;
            line-height: 38px;
        }
    }

    .accrodion-title {
        cursor: pointer;
        position: relative;
        padding-left: 30px;
        padding-right: 30px;

        &:after {
            content: "+";
            font-weight: 400;
            color: #898989;
            font-size: 23px;
            position: absolute;
            top: 0;
            right: 20px;
            font-family: Open Sans,sans-serif;
            line-height: 46px;
        }

        h4 {
            font-family: Open Sans,sans-serif;
            line-height: 46px;
            color: #242424;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: .04em;
            margin: 0;
        }
    }

    .accrodion-content {
        padding-left: 30px;
        padding-top: 19px;
        padding-bottom: 30px;

        h3, h4 {
            color: #242424;
            margin: 0 0 15px;
        }

        p {
            font-size: 14px;
            color: #898989;
            line-height: 24px;
            margin: 0 0 20px;
        }

        ul {
            margin-top: 19px;

            li {
                font-size: 14px;
                line-height: 15px;
                color: #898989;
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }

                &:before {
                    content: "\f105";
                    font-family: FontAwesome;
                    color: #898989;
                    margin-right: 15px;
                }
            }
        }

        .img-caption {
            .content-box {
                display: table-cell;
                vertical-align: top;
            }

            .img-box {
                display: table-cell;
                vertical-align: top;
                width: 143px;
            }

            .content-box {
                padding-left: 25px;

                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
