﻿/* ==============================
  15. Inner Banner styles
============================== */

.inner-banner {
    padding-top: 126px;
    padding-bottom: 83px;
    background: #13314c url(../images/about/banner.jpg) 50% no-repeat;
    background-size: cover;

    h2 {
        font: 600 2.4em/1em PT Sans Narrow,sans-serif;
        text-transform: uppercase;
        color: #fff;

        &:after {
            content: "";
            background: #e6ab0c;
            width: 50px;
            height: 4px;
            margin-left: 10px;
        }
    }

    &:after {
        display: inline-block;
    }

    ul.breadcumb li {
        display: inline-block;
        margin-right: 6px;

        &:last-child {
            a:after, span:after {
                display: none;
            }
        }

        a {
            color: #298fc2;
            font-weight: 600;
            font-size: 13px;
            text-transform: uppercase;
            display: block;
        }

        span {
            color: #fff;
            font-weight: 600;
            font-size: 13px;
            text-transform: uppercase;
            display: block;
        }

        a i, span i {
            font-size: 14px;
            font-weight: 400;
            margin-right: 5px;
        }

        a:after, span:after {
            content: "\f105";
            font-family: FontAwesome;
            color: #fff;
            font-size: 13px;
            margin-left: 7px;
        }
    }
}

.inner-banner2 {
    /*padding-top: 75px;
  padding-bottom: 80px;*/
    padding-top: 50px;
    padding-bottom: 55px;
    background: #13314c url(../../images/default-source/industrial/staticimages/banner-bg.jpg) 50% no-repeat;
    background-size: cover;

    h1, h2 {
        font: 600 3.8em/1em PT Sans Narrow,sans-serif;
        color: #fff;
        margin: 0;
        padding: 0;
    }
}

.breadcumb-wrapper {
    background: #f2f4f6;
    border-bottom: 1px solid #e6e8e9;
    padding-top: 1.2em;
    padding-bottom: 1.2em;

    ul.breadcumb li {
        display: inline-block;
        margin-right: 6px;

        &:last-child {
            a:after, span:after {
                display: none;
            }
        }

        a {
            color: #298fc2;
            display: inline-block;
            font-weight: 700;
            font-size: 13px;
            text-transform: uppercase;
            display: block;
        }

        span {
            display: inline-block;
            font-weight: 700;
            font-size: 13px;
            text-transform: uppercase;
            display: block;
            color: #909aa2;
        }

        a i, span i {
            font-size: 14px;
            font-weight: 400;
            margin-right: 5px;
        }

        a:after, span:after {
            content: "\f105";
            display: inline-block;
            font-family: FontAwesome;
            color: #909aa2;
            font-size: 13px;
            margin-left: 13px;
        }
    }
}
