﻿/* ==============================
  22. Core Projects page styles
============================== */

.core-projects {
    position: relative;
    margin: 0;

    h1 {
        font: 700 1.9em/1.2em PT Sans Narrow,sans-serif;
        color: #000;
        text-transform: uppercase;
        position: relative;
        padding-bottom: 20px;
        letter-spacing: .3px;
        margin: 0 0 1em;

        &:before {
            content: "";
            width: 55px;
            height: 3px;
            display: inline-block;
            background: #298fc2;
            position: absolute;
            bottom: 0;
        }
    }

    h4 {
        font: 600 1.2em/1em Open Sans,sans-serif;
        color: #242424;
    }

    p {
        margin: 0 0 1.2em;
        padding: 0;
        font: 400 1em/1.8em Open Sans,sans-serif;
        letter-spacing: .3px;
        color: #777;
    }
}

.project-post-area {
    background: #f1f1f1;
    position: relative;

    .project-post {
        padding-bottom: 30px;
    }

    .project-fullscreen {
        width: 20%;
        float: left;
        position: relative;
    }
}

@media (max-width: 767px) {
    .project-post-area .project-post {
        padding-bottom: 0;
    }
}

.project-post-info {
    overflow: hidden;
}

@media (max-width: 1199px) {
    .project-post-area .project-fullscreen {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .project-post-info {
        padding-bottom: 30px;
        width: 500px;
        max-width: 100%;
        margin: 0 auto;
    }

    .project-post-area .project-fullscreen {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .project-post-info a {
        max-width: 570px;
        margin: 0 auto;
        display: block;
    }
}

.project-post-image {
    overflow: hidden;
    position: relative;
}

.project-post-info {
    a img {
        max-width: 100%;
        width: 370px;
        height: 281px;
    }

    .info-text {
        text-align: center;
        position: absolute;
        width: 100%;
        box-sizing: border-box;
        left: 0;
        right: 0;
        transition: all .3s linear 0s;
        bottom: -100px;
        z-index: 1000;
    }

    &:hover .info-text {
        bottom: 37%;
    }

    .info-text {
        h4 {
            font: 700 16px Open Sans,sans-serif;
            transition: all .3s linear 0s;
            margin: 0 0 20px;
        }

        a, h4 {
            letter-spacing: .3px;
            color: #fff;
            text-transform: uppercase;
        }

        a {
            font: 700 14px Open Sans,sans-serif;
            margin: 0;
            background: #fac012;
            border-radius: 3px;
            line-height: 38px;
            padding: 0 20px;
            height: 38px;
            display: inline-block;
        }
    }
}

.loadmore {
    margin: 50px 0;
    background: #298fc2;
    border-radius: 5px;
    padding: 0 25px;
    display: inline-block;
    line-height: 38px;
    height: 38px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
}

/* ==============================
  30. Project details page styles
============================== */

.pro-det-img img {
    max-width: 100%;
}

.pro-content {
    padding: 4em 0 0;
}

.projects_hours {
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    padding: 2em 0;
    margin: 1.5em 0 0;

    p {
        color: #242424;
        font: 600 1.3em/2em Open Sans,sans-serif;
        margin: 0;
        padding: 0;

        span {
            display: block;
            color: #777;
            font: 400 1em/1.8em Open Sans,sans-serif;
        }
    }
}

.project-con {
    background: #f5f5f5;
    padding: 34px 40px;
    margin: 3em 0;

    h2 {
        color: #242424;
        font: 600 1.3em/2em Open Sans,sans-serif;
        margin: 0;
        padding: 0 0 1em;
    }

    p {
        color: #898989;
        margin: 0 0 1.2em;
        padding: 0;
        font: 400 1em/1.8em Open Sans,sans-serif;
        letter-spacing: .3px;
    }
}

.project-scop {
    .pro-img img {
        max-width: 100%;
    }

    h3 {
        color: #242424;
        font: 600 1.3em/1em Open Sans,sans-serif;
        margin: 0;
        padding: 0 0 1em;
    }

    p {
        margin: 0 0 1.2em;
        padding: 0;
        font: 400 1em/1.8em Open Sans,sans-serif;
        letter-spacing: .3px;
        color: #777;
    }

    .scope-item {
        margin: 0;
        display: inline-block;
        padding: 7px 0;

        li {
            list-style: none;
            font: 400 1.1em/1.5em Open Sans,sans-serif;
            display: block;
            padding-left: 30px;
            padding-bottom: 10px;

            &:before {
                content: "\f0a4";
                width: 30px;
                height: auto;
                font: normal normal normal 14px/24px FontAwesome;
                font-size: 16px;
                text-rendering: auto;
                cursor: pointer;
                float: left;
                margin-left: -30px;
            }
        }
    }
}