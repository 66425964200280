﻿/* ==============================
  27. Contact us styles
============================== */

.touch .touch_bg {
    .section_header p {
        max-width: 840px;
    }

    .touch_top-con ul .item {
        padding: 15px 28px 30px 0;
        text-align: center;

        .media {
            .media-left {
                padding-right: 30px;
                vertical-align: middle;

                a {
                    color: #298fc2;
                    border: 1px solid #298fc2;
                    border-radius: 100%;
                    display: block;
                    width: 50px;
                    height: 50px;

                    i {
                        border-radius: 50%;
                        font-size: 30px;
                        line-height: 32px;
                        padding-top: 8px;
                    }
                }
            }

            .media-body {
                text-align: left;
                font: 14px/26px Open Sans,sans-serif;
                letter-spacing: .3px;

                a:hover {
                    color: #298fc2;
                }
            }
        }

        &:first-child {
            padding-top: 0;
        }
    }

    .touch_middle {
        padding: 80px 0;

        .input_form {
            position: relative;

            form {
                .form-control {
                    margin-bottom: 20px;
                    border-radius: 0;
                    font: 15px/31px Open Sans,sans-serif;
                    color: #959595;
                    padding: 11px 20px;
                    border: 1px solid #e1e1e1;
                    height: auto;
                    box-shadow: none;

                    &:focus, &:hover {
                        border: 1px solid #d6d6d6;
                    }

                    &:focus {
                        box-shadow: 0 0 6px 0 rgba(215, 149, 62, 0.57);
                    }

                    &.error {
                        margin-bottom: 0;

                        + .error {
                            font: 13px/24px Open Sans,sans-serif;
                            margin-bottom: 20px;
                            margin-top: 5px;
                            color: red;
                            font-weight: 400;
                        }
                    }
                }

                textarea.form-control {
                    margin-bottom: 32px;
                    resize: none;
                    padding: 15px 20px;
                    height: 203px;
                }

                button {
                    background: #f6ba18;
                    border: 1px solid #f6ba18;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    font: 600 1.2em/2.1em PT Sans Narrow,sans-serif;
                    padding: 7px 22px;
                    color: #19242a;
                    margin-top: 10px;
                    border-radius: 5px;
                    letter-spacing: 3px;

                    &:hover {
                        color: #000;
                        border-color: #bb793c;
                    }
                }
            }
        }
    }
}

#mapBox {
    height: 425px;
}

#map-area {
    position: relative;

    &:before {
        background: #000 none repeat scroll 0 0;
        content: "";
        height: 100%;
        left: 0;
        opacity: .4;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.touch {
    p {
        margin: 0 0 1.2em;
        padding: 0;
        font: 400 1em/1.8em Open Sans,sans-serif;
        color: #8e8d8d;
    }

    h6 {
        color: #1088cc;

        span {
            color: #de5842;
        }
    }
}
