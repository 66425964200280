﻿ul {
    margin: 0;
    padding: 0;
    list-style: none;

    .tab-content & {
        margin-left: 10px;
        margin-bottom: 15px;
        padding-left: 20px;
        list-style: disc;

        li {
            padding-left: 3px;

            ul {
                list-style: circle;
                margin-bottom: 0;
            }
        }
    }
}
