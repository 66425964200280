﻿/* ==============================
  19. Services Page styles 
============================== */

.service-tab-box {
    h1 {
        font: 700 2.2em/1.2em PT Sans Narrow,sans-serif;
        color: #000;
        text-transform: uppercase;
        position: relative;
        padding-bottom: 20px;
        letter-spacing: .3px;
        margin: 0 0 1em;

        &:before {
            content: "";
            width: 55px;
            height: 3px;
            display: inline-block;
            background: #298fc2;
            position: absolute;
            bottom: 0;
        }
    }

    p {
        margin: 0 0 1.2em;
        font: 400 1em/1.8em Open Sans,sans-serif;
        color: #777;
    }

    h6, p {
        padding: 0;
        letter-spacing: .3px;
    }

    h6 {
        color: #242424;
        margin: 0 0 .5em;
        font: 400 1.4em/1.8em Open Sans,sans-serif;
    }

    ul.c-brochure {
        display: inline-block;

        li {
            background: #298fc2;

            a {
                font: 400 1.2em/1.2em Open Sans,sans-serif;

                span {
                    display: inline-block;
                    padding: .7em 1.5em;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                }

                i {
                    background: #e7ab08;
                    padding: 1em;
                }
            }
        }
    }

    .img-s-box {
        overflow: visible;
        font-size: 0;
        float: right;

        .img-so-box {
            &:after {
                content: "";
                position: absolute;
                top: 10px;
                left: 10px;
                right: 10px;
                bottom: 10px;
                border: 2px solid #fff;
                border-bottom: 0;
                border-left: 0;
            }

            img {
                max-width: 100%;
                display: block;
            }
        }

        .img-st-box img {
            max-width: 100%;
            display: block;
        }
    }
}
