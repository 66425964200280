﻿/* ==============================
   16. About Tab Box styles
   ============================== */

.about-tab-box {
    .tab-title-box {
        margin-bottom: 50px;

        ul li {
            background: #fff;
            transition: all .3s ease;
            position: relative;
            margin: 0 0 1.5em;
            border: 2px solid #e0e0e0;

            &.active, &:hover {
                background: #298fc2;
                border: 2px solid transparent;
            }

            &:before {
                position: absolute;
                top: -2px;
                left: -2px;
                width: 6px;
                height: 106%;
                background: transparent;
                content: "";
                transition: all .3s ease;
            }

            &.active:before, &:hover:before {
                background: #e6ab0c;
            }

            &.active:after {
                opacity: 1;
            }

            &:hover a {
                color: #fff;
            }

            a {
                color: #fff;
                text-align: left;
                font: 500 1.54em/1em Open Sans,sans-serif;
                display: block;
                font-weight: 700;
                color: #242424;
                padding: 1em;
                transition: all .3s ease;
                text-transform: uppercase;
            }
        }
    }

    .single-tab-content {
        .content-box {
            margin-left: 20px;
        }

        h2 {
            font: 700 2.2em/1.2em PT Sans Narrow,sans-serif;
            color: #000;
            text-transform: uppercase;
            position: relative;
            padding-bottom: 20px;
            letter-spacing: .3px;
            margin: 0 0 1em;

            &:before {
                content: "";
                width: 55px;
                height: 3px;
                display: inline-block;
                background: #298fc2;
                position: absolute;
                bottom: 0;
            }
        }

        h3 {
            font-size: 20px;
            font-family: Open Sans;
            color: #242424;
            line-height: 26px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        p {
            color: #8e8d8d;
            margin: 0 0 1em;
            font: 400 1em/1.8em Open Sans,sans-serif;
        }

        ul li {
            color: #333e56;
            font-size: 15px;
            text-transform: uppercase;
            line-height: 40px;
            font-weight: 600;

            .fa {
                font-size: 18px;
                color: #ff7043;
                margin-right: 5px;
            }
        }

        .abot-img {
            display: inline-block;
            margin-top: 2em;

            img {
                max-width: 100%;
                float: left;

                &:first-child {
                    margin: 0 2.4em 0 0;
                }
            }
        }
    }
}

.about-tab {
    padding: 0 0 0 5em;
}

.features-section {
    overflow: hidden;

    .features-image {
        width: calc(50% - 210px);
        float: left;
        display: block;
        text-align: right;
    }

    .features-area {
        width: calc(50% + 210px);
        float: left;
        position: relative;

        &:after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background: #143b53;
            top: 0;
            left: 0;
            display: block;
            z-index: 1;
        }

        .features {
            max-width: 850px;
            position: relative;
            z-index: 2;

            h4 {
                font: 700 16px/26px PT Sans Narrow,sans-serif;
                text-transform: uppercase;
                margin-bottom: 8px;
                letter-spacing: .3px;
                color: #fff;
            }

            p {
                letter-spacing: .3px;
                color: #fff;
                font: 500 16px/26px PT Sans Narrow,sans-serif;
                margin-bottom: 40px;
                opacity: .5;
            }

            .features-content {
                width: 50%;
                float: left;
                padding: 80px 30px 27px 35px;

                &:first-child {
                    background: #143b53;
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .features-section {
        .features-area {
            width: 100%;
        }

        .features-image {
            width: 100%;

            img {
                width: 100%;
            }
        }
    }
}

@media (max-width: 1199px) {
    .features-section .features-area .features {
        margin: 0 auto;
    }
}

@media (max-width: 639px) {
    .features-section .features-area .features .features-content {
        width: 100%;
    }
}
