﻿/* ==============================
  35. LOOKING AN ADEQUATE
============================== */

.looking-wrapper {
    background: #53565a;
    padding-top: 3em;
    padding-bottom: 3em;
    position: relative;
}

.indurial-solution-text2 h2 {
    font: 700 2.57em/1em Open Sans Condensed,sans-serif;
    color: #fff;
    margin: 0;
    padding: 0;
}

.looking-wrapper .req-button a {
    background: #000;
    color: #fff;
    border-radius: 4px;
    font: 600 1.03em/1.3em PT Sans Narrow,sans-serif;
    padding: .8em 2.5em;

    i {
        margin-left: 6px;
    }
}

.looking-wrapper3 {
    min-height: 113px;
    padding: 34px 0;

    .indurial-solution-text2 h2 {
        font: 300 24px Open Sans,sans-serif;
        color: #000;
        margin: 5px 0;
    }

    .req-button a {
        background: #0f263a;
        padding: 1em 1.52em;
    }
}
