﻿.image-fill {
    width: 100%;
}

.post-image {
    .featured-technologies-block & {
        max-width: 370px;
        max-height: 225px;

        @include media-breakpoint-up(lg) {
            max-height: 156px;
        }

        @include media-breakpoint-up(xl) {
            max-height: 195px;
        }
    }

    // blog overview page
    .blog-post-list & {
        max-width: 370px;
        max-height: 225px;

        @include media-breakpoint-up(lg) {
            max-height: 182px;
        }

        @include media-breakpoint-up(xl) {
            max-height: 225px;
        }
    }

    // home blog list
    .blog-post-list.four-column & {
        @include media-breakpoint-up(lg) {
            max-height: 129px;
        }

        @include media-breakpoint-up(xl) {
            max-height: 160px;
        }
    }
}

.image_hover {
    display: inline-block;
    position: relative;
    overflow: hidden;

    &:focus:after {
        opacity: 1;
    }

    &:hover:after {
        opacity: 1;
        opacity: .5;
    }
}

.zoom_img_effect {
    position: relative;
    transition: all .3s ease-out;
}

.image_hover:hover .zoom_img_effect {
    transform: scale(1.05) translateZ(0);
}

.four_arrow_hover_box {
    display: block;
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    opacity: 0;
    border: 7px solid rgba(3, 137, 158, 0.6);
    transform: scaleX(0);
    transform-origin: left;
    transition: all .3s linear 0s;

    .arrows {
        border: 1px solid #fff;

        span {
            background: rgba(3, 137, 158, 0.6);
        }

        &:after {
            content: "";
        }

        &:before {
            content: "";
            border-top: 1px solid rgba(3, 137, 158, 0.6);
            border-bottom: 1px solid rgba(3, 137, 158, 0.6);
            top: -1px;
            bottom: -1px;
            left: 16px;
            right: 16px;
        }

        &:after {
            border-left: 1px solid rgba(3, 137, 158, 0.6);
            border-right: 1px solid rgba(3, 137, 158, 0.6);
            left: -1px;
            right: -1px;
            top: 16px;
            bottom: 16px;
        }
    }
}

.intro-image-container {
    margin-bottom: 28px;
    border-radius: $border-radius;

    @include media-breakpoint-up(lg) {
        max-width: 373px;
        max-height: 226px;
        float: right;
        overflow: hidden;
        margin-left: 48px;
    }

    img {
        max-height: 226px;
    }
}