﻿/* checkout page styles */

#checkout-content {
    .return-customer {
        background: #f9f9f9 none repeat scroll 0 0;
        border: 1px solid #ebebeb;
        margin-bottom: 45px;
        padding: 21px 30px;

        p {
            color: #898989;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 0;
        }

        a {
            color: #11a9e0;
        }
    }

    input[type=text] {
        display: block;
    }

    label {
        display: block;
        color: #8e8d8d;
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        margin-bottom: 4px;
    }

    input[type=text] {
        border: 1px solid #e2e2e2;
        color: #c6c6c6;
        font-size: 14px;
        font-weight: 400;
        height: 40px;
        line-height: 40px;
        margin-bottom: 10px;
        outline: medium none;
        padding: 0 20px;
        width: 100%;
    }
}

.left-checkout .section-title2.ship-to-different {
    margin-bottom: 39px;
    margin-top: 68px;
}

#checkout-content {
    input[type=text]:last-child {
        margin-bottom: 0;
    }

    .left-checkout > .row {
        margin-bottom: 23px;
    }

    .row span b {
        color: #8e8d8d;
        font-size: 14px;
        font-weight: 500;
    }

    input[type=checkbox] {
        display: inline-block;
        vertical-align: middle;
        float: left;
        margin: 4px 15px 0 0;
    }

    textarea {
        width: 100%;
        height: 100px;
        border: 1px solid #e2e2e2;
        padding: 10px 20px;
        outline: none;
    }

    .ship-different {
        margin-top: 80px;
    }

    .order-box {
        border: 1px solid #d9d9d9;
        padding: 30px;

        ul li {
            color: #8e8d8d;
            display: block;
            font-size: 14px;
            font-weight: 400;
            line-height: 45px;

            &.total {
                border-bottom: 1px solid #e2e2e2;
                border-top: 1px solid #e2e2e2;
                color: #242424;
            }

            span {
                color: #898989;
                display: inline-block;
                float: right;
                font-size: 14px;

                &.bold {
                    color: #242424;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

            &.bold {
                font-weight: 500;
            }

            a span {
                font-weight: 500;
                color: #11a9e0;
                font-size: 13px;
                font-family: PT Sans Narrow,sans-serif;
            }

            &.place-order-button {
                padding-top: 20px;
                line-height: 30px;
            }

            input[type=radio] {
                margin-right: 10px;
            }

            .note {
                background: #ececec;
                padding: 25px;
                color: #8e8d8d;
                line-height: 24px;
                font-size: 14px;
                font-weight: 400;
                position: relative;

                .fa {
                    font-size: 35px;
                    position: absolute;
                    top: -22px;
                    left: 25px;
                    color: #ececec;
                }
            }

            a.place-order {
                background: #fd9c1e;
                text-transform: uppercase;
                display: inline-block;
                line-height: 36px;
                font-weight: 700;
                color: #fff;
                padding: 0 40px;
                margin-top: 30px;
            }
        }
    }

    .select-menu + .ui-selectmenu-button {
        width: 100% !important;
        border-radius: 0;
        padding: 0 20px;
        border: 1px solid #e5e5e5;
        height: 42px;
        background: #fff;
        outline: none;
        color: #898989;

        .ui-selectmenu-text {
            padding: 0;
            font-weight: 400;
            font-family: Open Sans,sans-serif;
            line-height: 42px;
            color: #898989;
            text-transform: capitalize;
        }

        .ui-icon.ui-icon-triangle-1-s {
            background: url(../images/select-menu-arrow.png) 0 0 no-repeat;
            width: 14px;
            height: 6px;
            margin-top: -3px;
            margin-right: 8px;
        }
    }

    .thm-btn {
        background: #298fc2;
        border-radius: 5px;
        color: #000;
        text-transform: uppercase;
        line-height: 42px;
        display: inline-block;
        letter-spacing: .05em;
        font-family: Open Sans;
        font-weight: 600;
        padding: 0 18px;
        margin-top: 10px;
        float: right;

        i {
            font-size: 17px;
            margin-left: 7px;
        }

        &:hover {
            background: #1fb5c7;
            color: #fff;
            border-color: #1fb5c7;
        }
    }
}
