﻿/* product details page styles */

.product-details-content {
    margin-bottom: 20px;

    .content-box {
        display: table-cell;
        vertical-align: top;
    }

    .img-box {
        display: table-cell;
        vertical-align: top;
        width: 306px;
        border: 1px solid #e2e2e2;
    }

    .content-box {
        padding-left: 65px;
    }

    .content-box2 {
        padding-left: 0;
    }

    .content-box {
        .title h3 {
            font-size: 20px;
            color: #242424;
            text-transform: uppercase;
            font-weight: 800;
            font-family: PT Sans Narrow,sans-serif;
            margin: 0;
        }

        p {
            font-size: 14px;
            color: #959595;
            line-height: 26px;
        }

        .content-text {
            border-top: 1px solid #eaeaea;
            border-bottom: 1px solid #eaeaea;
            margin-top: 20px;
            padding-top: 19px;
            padding-bottom: 15px;
            margin-bottom: 30px;
        }

        .cart-btn {
            font-size: 15px;
            line-height: 54px;
            background: #1fb5c7;
            letter-spacing: .1em;
            text-transform: uppercase;
            font-weight: 600;
            color: #fff;
            display: inline-block;
            margin-top: 28px;
            background: #298fc2;
            font-size: 20px;
            font-weight: 700;
            font-family: PT Sans Narrow,sans-serif;
            color: #000;
            border-radius: 5px;
            padding: 0 49px;

            i {
                font-size: 25px;
                margin-right: 20px;
            }
        }

        .price, .quantity {
            font-size: 16px;
            color: #1f1f1f;
        }

        .price span {
            font-size: 35px;
            color: #292929;
            margin-left: 20px;
            font-style: italic;
            font-family: PT Sans Narrow;
        }

        .quantity {
            margin-top: 20px;

            input {
                width: 40px;
                height: 35px;
                border: 1px solid #d9d9d9;
                text-align: center;
                line-height: 33px;
                padding-left: 10px;
                margin-left: 20px;
            }
        }
    }
}

.product-tab-box {
    margin-bottom: 80px;

    .tab-title-box ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            display: inline-block;
            margin-right: 6px;

            &.active a {
                background: #fff;
                border-bottom-color: #fff;
                border-top: 3px solid #298fc2;
                line-height: 44px;
            }

            a {
                line-height: 44px;
                display: block;
                font-size: 13px;
                text-transform: uppercase;
                background: #f7f7f7;
                color: #242424;
                padding: 0 29px;
            }
        }
    }

    .tab-content, a {
        border: 1px solid #e2e2e2;
    }

    .tab-content {
        margin-top: -1px;
        padding: 50px 40px 65px;

        h3 {
            font-size: 18px;
            margin: 0 0 12px;
        }

        p {
            color: #898989;
            font-size: 14px;
            margin-top: 15px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}


/* ==============================
  39. product solutions  
============================== */

.our-sol-wrapper {
    background: url(../images/event/pro-sol-bg.jpg) no-repeat top;
    position: relative;
    overflow: hidden;
    padding: 3em 0;
    background-size: cover;

    p {
        font: 300 32px Open Sans,sans-serif;
        margin: 0;
        padding: 0;
        color: #fff;
        text-align: center;

        span {
            color: #53565a;
        }
    }
}

.ind-common-pad {
    padding-top: 5.5em;
    padding-bottom: 5.5em;
}