﻿/* cart page styles */

.cart-page {
    padding: 80px 0;

    .table {
        margin: 0;
    }

    table > tbody > tr > td {
        border: none;
    }

    .table {
        > thead > tr > th {
            border: none;
        }

        thead {
            background: #f9f9f9;
        }

        > {
            thead > tr > th {
                font-weight: 400;
                color: #000;
                font-size: 13px;
                line-height: 60px;
                padding: 0 40px;
                font-weight: 500;
                border: 1px solid #ebebeb;
                border-bottom: 0;
                text-transform: uppercase;
                text-align: center;

                &.close {
                    color: transparent;
                }
            }

            tbody > tr > td {
                border: 1px solid #ebebeb;
                color: #898989;
                font-size: 14px;
                font-weight: 500;
                line-height: 42px;
                padding: 60px 25px;
                text-align: center;

                &.preview {
                    padding: 25px 50px;
                }

                &.price {
                    font-size: 16px;
                }

                &.del-item {
                    text-align: center;
                }

                &.total {
                    font-size: 16px;
                }

                .select-box select:focus {
                    outline: none;
                }

                &.del-item {
                    color: #c56e21;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }
    }

    .bottom-box {
        background: #f9f9f9 none repeat scroll 0 0;
        margin: 0 0 50px;
        padding: 30px 25px;
        border-left: 1px solid #ebebeb;
        border-bottom: 1px solid #ebebeb;
        border-right: 1px solid #ebebeb;

        .cupon-box {
            input {
                border: 1px solid #eee;
                color: #8e8d8d;
                margin-right: 10px;
                outline: medium none;
                width: 130px;
            }

            button, input {
                line-height: 36px;
                padding: 0 20px;
            }

            button {
                color: #fff;
                background: #e3e3e3;
                text-transform: capitalize;
                border: none;
                outline: none;
                font-weight: 400;
                color: #000;
            }
        }
    }

    .add-to-cart-wrap {
        text-align: right;

        a {
            .cart-button {
                background: #252525 none repeat scroll 0 0;
                border-radius: 5px;
                display: inline-block;

                p {
                    color: #fff;
                    font-size: 12px;
                    margin: 0;
                    padding-left: 21px;
                    padding-right: 17px;
                    text-transform: uppercase;
                    border-right: 1px solid #000;
                }

                i, p {
                    display: inline-block;
                    float: left;
                    line-height: 35px;
                }

                i {
                    border-left: 1px solid #404040;
                    color: #98bc24;
                    font-size: 17px;
                    padding-left: 14px;
                    padding-right: 15px;
                }
            }

            &:before {
                background: #98bc24;
            }
        }
    }

    .cart-total-box {
        li {
            border-bottom: 1px solid #ebebeb;
            border-left: 1px solid #ebebeb;
            border-right: 1px solid #ebebeb;
            font-weight: 500;
            line-height: 58px;
            padding: 0 25px;
            color: #000;
            font-size: 13px;
            text-transform: uppercase;

            &:first-child {
                border-top: 1px solid #ebebeb;
            }

            span {
                color: #898989;
                margin-left: 50px;
                font-size: 16px;
            }
        }

        .cart-total {
            background: #f9f9f9;
        }

        .proceed-to-checkout {
            text-align: right;
            padding-top: 10px;
            border: 1px solid #ebebeb;
            border-top: 0;
            padding-bottom: 10px;
            padding-right: 20px;

            a {
                line-height: 30px;
                font-size: 14px;

                .cart-button {
                    background: #252525 none repeat scroll 0 0;
                    border-radius: 5px;
                    margin-top: 24px;

                    p {
                        border-right: 1px solid #000;
                        color: #fff;
                        float: left;
                        font-size: 12px;
                        line-height: 40px;
                        margin: 0;
                        padding-left: 21px;
                        padding-right: 17px;
                        text-transform: uppercase;
                    }
                }
            }

            .cart-btn, p {
                text-transform: uppercase;
            }

            .cart-btn {
                background: #298fc2;
                border-radius: 5px;
                color: #000;
                line-height: 32px;
                display: inline-block;
                letter-spacing: .05em;
                font-family: Open Sans;
                font-weight: 600;
                padding: 0 18px;
                margin-top: 10px;

                i {
                    font-size: 17px;
                    margin-right: 7px;
                }

                &:hover {
                    background: #1fb5c7;
                    color: #fff;
                    border-color: #1fb5c7;
                }
            }

            a {
                .cart-button i {
                    border-left: 1px solid #404040;
                    color: #98bc24;
                    display: inline-block;
                    float: left;
                    font-size: 17px;
                    line-height: 40px;
                    padding-left: 14px;
                    padding-right: 15px;
                }

                &:before {
                    background: #98bc24;
                }
            }
        }
    }

    .select-box {
        width: 100px;
    }

    .select-menu + .ui-selectmenu-button {
        width: 100% !important;
        border-radius: 0;
        padding: 0 40px 0 20px;
        border: 1px solid #e5e5e5;
        height: 42px;
        background: #fff;
        outline: none;
        color: #898989;

        .ui-selectmenu-text {
            border-right: 1px solid #e5e5e5;
        }
    }

    .thm-btn {
        font-size: 14px;
        padding: 7px 23px;
    }

    .select-menu + .ui-selectmenu-button {
        .ui-selectmenu-text {
            padding: 0;
            font-weight: 400;
            font-family: Open Sans,sans-serif;
            line-height: 42px;
        }

        .ui-icon.ui-icon-triangle-1-s {
            background: url(../images/select-menu-arrow.png) 0 0 no-repeat;
            width: 16px;
            height: 6px;
            margin-top: -3px;
            margin-right: 4px;
        }
    }

    .bottom-box .cart-btn {
        background: #298fc2;
        border-radius: 5px;
        color: #000;
        text-transform: uppercase;
        line-height: 32px;
        display: inline-block;
        letter-spacing: .05em;
        font-family: Open Sans;
        font-weight: 600;
        padding: 0 18px;
        margin-top: 10px;

        i {
            font-size: 17px;
            margin-right: 7px;
        }

        &:hover {
            background: #d6b161;
            color: #fff;
            border-color: #d6b161;
        }
    }
}

.ui-selectmenu-menu {
    .ui-menu {
        background: #fff;
    }

    .ui-menu-item {
        font-size: 16px;
        font-family: "PT Serif",sans-serif;
        font-style: italic;
        padding: 5px 20px;
        border-bottom: 1px solid #e1e1e1;
        text-transform: capitalize;

        &.ui-state-focus {
            background: #00acd9;
            border-color: #00acd9;
            color: #fff;
            font-weight: 400;
        }
    }
}
