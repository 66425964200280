﻿/* ==============================
  21. Single service page styles
============================== */

.single-post-page .image-box, .single-service-page .image-box {
    margin-bottom: 50px;
}

.single-post-page .image-caption-box, .single-service-page .image-caption-box {
    margin-bottom: 20px;
}

.single-post-page .image-caption-box {
    .caption-box-img, .text-box {
        display: table-cell;
        vertical-align: middle;
    }
}

.single-service-page .image-caption-box {
    .caption-box-img, .text-box {
        display: table-cell;
        vertical-align: middle;
    }
}

.single-post-page .image-caption-box .text-box p, .single-service-page .image-caption-box .text-box p {
    color: #898989;
    margin: 0 0 1.2em;
    padding: 0;
    font: 400 1em/1.8em Open Sans,sans-serif;
    letter-spacing: .3px;
}

.single-post-page .image-caption-box .caption-box-img, .single-service-page .image-caption-box .caption-box-img {
    width: 248px;
}

.pad46 {
    padding-right: 46px;
}
