﻿/* ==============================
  24. Page Navigation styles
============================== */

.page-navigation {
    margin-top: 50px;

    li {
        display: inline;

        a {
            background: #fff;
            color: #8e8d8d;
            width: 46px;
            height: 46px;
            line-height: 44px;
            font-size: 20px;
            display: inline-block;
            border: 1px solid #e2e2e2;
            margin-right: 5px;
            text-align: center;
            transition: all .3s ease;
        }

        span {
            width: 46px;
            height: 46px;
            line-height: 44px;
            font-size: 20px;
            display: inline-block;
            border: 1px solid #e2e2e2;
            margin-right: 5px;
            text-align: center;
            transition: all .3s ease;
            background: #fff;
            color: #8e8d8d;
            background: #e7e7e7;
            color: #242424;
        }

        a:hover {
            background: #e7e7e7;
            color: #242424;
        }
    }
}
