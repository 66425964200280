﻿/* ==============================
  43. Solution Page
============================== */

.indurial-t-solution3 {
    background: url(../images/solution/solution-bg.jpg) 50% no-repeat fixed;
    background-size: cover;
    padding: 29px 0;
    min-height: 100px;
    clear: both;

    h2 {
        font-family: Open Sans,sans-serif;
        font-style: normal;
        font-weight: 300;
        padding: 6px 0 0 50px;
        float: left;
    }

    .contactus-button2 {
        margin-left: 0;

        a {
            margin: 0;
        }
    }
}

/* ==============================
  44. Solution Page
============================== */

#contact-google-map {
    width: 100%;
    height: 500px;
}

.map-section {
    clear: both;
}

.map-outer {
    position: relative;
}

.map-section {
    position: relative;

    .outer-container {
        position: absolute;
        max-width: 1170px;
        padding: 0 15px;
        margin: 0 auto;
        z-index: 20;

        .info-container {
            position: relative;
            max-width: 1000px;
            margin: 0 auto;
            bottom: 375px;
            padding: 30px 50px 20px;
            background: #1a3b66;
            border: 10px solid #fff;
            z-index: 4;
            float: left;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

            address {
                h4 {
                    font-size: 30px;
                    color: #fff;
                    font-weight: 700;
                    padding-bottom: 20px;
                }

                .info {
                    margin-bottom: 20px;
                }

                .fa, .info {
                    float: left;
                }

                .fa {
                    color: orange;
                    font-size: 16px;
                    margin-right: 10px;
                }

                p {
                    font-size: 14px;
                    color: #fff;
                    float: left;
                }
            }
        }
    }
}

.diff-offer-wrapper3 {
    padding: 0 0 38px;
    min-height: 661px;

    .diff-offer {
        padding: 74px 0;

        h2 {
            margin: 0;
            font-weight: 300;
            line-height: 40px;

            a {
                color: #3cbfe7;
                text-decoration: underline;
                font-weight: 500;
            }
        }
    }

    .service-info {
        .post-image {
            margin: 0 0 30px;
        }

        h4 {
            margin: 0;

            a {
                color: #242424;
                font: 600 18px Open Sans,sans-serif;
                text-transform: uppercase;
                transition: all .3s linear 0s;
                margin: 0 0 15px;
            }
        }

        p {
            padding: 0;
            margin: 0 0 18px;
        }
    }

    .service-info-right {
        .post-image {
            width: 100px;
        }

        .post-content {
            float: left;
            width: 260px;
            padding: 0 0 0 15px;
            box-sizing: border-box;
        }

        .post-image img {
            width: 100px;
            height: 100px;
        }

        h4 a {
            margin: 0;
        }
    }

    .service-info {
        h6 {
            margin: 0;
        }

        .item {
            float: left;
        }
    }
}