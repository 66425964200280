﻿/* ==============================
  6. Welcome to industrial styles
============================== */

.wel-ind-pad {
    padding: 5.7em 0 1em;
}

.wel-info {
    position: relative;
    overflow: hidden;

    h1 {
        font: 600 2.4em/1em PT Sans Narrow,sans-serif;
        color: #282c3d;
        margin: 0 0 .8em;
        text-transform: uppercase;
    }

    p {
        margin: 0 0 1.2em;
        padding: 0;
        font: 400 1em/1.8em Open Sans,sans-serif;
        letter-spacing: .3px;
        color: #777;
    }
}

.wel-bot {
    padding: 1.2em 0 0;

    img {
        float: left;
        border: 1px solid #cecece;
        padding: .4em;
        margin: 0 1.8em 0 0;
        height: 92px;
        width: 104px;
    }

    h2 {
        font: 400 1.5em/1.2em Open Sans,sans-serif;
        color: #298fc2;
        margin: 0;
        padding: 0;
    }

    p {
        color: #777;
        font-style: italic;
        padding: .8em 0 0;
        opacity: .5;
    }
}

.wel-ind-image {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        border: 2px solid #fff;
        z-index: 4;
        display: block;
        top: 10px;
        left: 10px;
        bottom: 10px;
        right: 10px;
    }

    img {
        max-width: 100%;
        width: 470px;
        height: 260px;
    }
}

.wel-ind-r-sec {
    position: relative;
}

.wel-ind-r-txt {
    float: left;
    margin: 2em;

    p {
        font: 400 1.5em/1.2em Open Sans,sans-serif;
        font-style: italic;
    }
}

.wel-ind-r-img {
    float: right;
    position: relative;
    bottom: 4em;
    z-index: 4;

    img {
        border-top: 7px solid #fff;
        border-left: 7px solid #fff;
        width: 256px;
        height: 158px;
    }
}
