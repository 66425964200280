﻿/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 32. TT-GALLERY-1 */
/*-------------------------------------------------------------------------------------------------------------------------------*/

.tt-gallery-1 {
    display: block;
    position: relative;
    overflow: hidden;
}

.tt-gallery-1-caption {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    text-align: center;
    opacity: 0;
    transition: all .35s ease-in-out;
    z-index: 1000;
}

.tt-gallery-1-caption-table {
    display: table;
    position: relative;
    width: 100%;
    height: 100%;
}

.tt-gallery-1-caption-inner {
    display: table-cell;
    vertical-align: middle;
    transform: translateY(-15%);
    transition: all .35s ease-in-out;
}

.tt-gallery-1-name {
    display: block;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
    letter-spacing: .5px;
    margin-bottom: 3px;
}

.tt-gallery-1-cat {
    display: block;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #898989;
    letter-spacing: .2px;
}

.tt-gallery-1-search {
    display: inline-block;
    width: 66px;
    height: 66px;
    font-size: 22px;
    line-height: 66px;
    color: #000;
    text-align: center;
    background: #fac012;
    border-radius: 50%;
}

.tt-gallery-1:hover {
    .tt-gallery-1-caption {
        opacity: 1;
    }

    .tt-gallery-1-caption-inner {
        transform: translateY(0);
    }
}

.tt-gallery-1-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .1;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* 33. LIGHTBOX */
/*-------------------------------------------------------------------------------------------------------------------------------*/

#imagelightbox {
    position: fixed;
    z-index: 9999;
    touch-action: none;
}

#imagelightbox-close {
    width: 2.5em;
    height: 2.5em;
    text-align: left;
    background-color: #05305a;
    position: fixed;
    z-index: 10002;
    top: 5em;
    right: 2.5em;
    transition: color .3s ease;
    border: none;

    &:after {
        width: 2px;
        background-color: #fff;
        content: "";
        position: absolute;
        top: 20%;
        bottom: 20%;
        left: 50%;
        margin-left: -1px;
    }

    &:before {
        width: 2px;
        background-color: #fff;
        content: "";
        position: absolute;
        top: 20%;
        bottom: 20%;
        left: 50%;
        margin-left: -1px;
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }

    &:hover {
        background-color: #fff;
        outline: none;

        &:after, &:before {
            background: #05305a;
        }
    }
}

#imagelightbox-loading {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    background-color: #444;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 10003;
    top: 50%;
    left: 50%;
    padding: .625em;
    margin: -1.25em 0 0 -1.25em;
    box-shadow: 0 0 2.5em rgba(0, 0, 0, 0.75);

    div {
        border-radius: 50%;
        width: 1.25em;
        height: 1.25em;
        background-color: #fff;
        animation: imagelightbox-loading .5s ease infinite;
    }
}

#imagelightbox-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    z-index: 9998;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

#imagelightbox-caption {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    background-color: #05305a;
    position: fixed;
    z-index: 10001;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 14px 0;
}

.imagelightbox-arrow {
    font-size: 40px;
    line-height: 56px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.32);
    width: 3.75em;
    height: 7.5em;
    background-color: transparent;
    vertical-align: middle;
    display: none;
    position: fixed;
    z-index: 10001;
    top: 50%;
    margin-top: -3.75em;
    border: none;
}

.imagelightbox-arrow-left {
    left: 2.5em;
    transition: all .3s ease-in-out;
}

.imagelightbox-arrow-right {
    transition: all .3s ease-in-out;
    right: 2.5em;
}

.imagelightbox-arrow-left:focus, .imagelightbox-arrow-right:focus {
    outline: none;
}

.imagelightbox-arrow-left:hover, .imagelightbox-arrow-right:hover {
    color: #fff;
}

#imagelightbox-caption, #imagelightbox-close, #imagelightbox-loading, #imagelightbox-nav, #imagelightbox-overlay, .imagelightbox-arrow {
    animation: fade-in .25s linear;
}

@media only screen and (max-width: 660px) {
    #container {
        width: 100%;
    }

    #imagelightbox-close {
        top: 1.25em;
        right: 1.25em;
    }

    #imagelightbox-nav {
        bottom: 1.25em;
    }

    .imagelightbox-arrow {
        width: 2.5em;
    }

    .imagelightbox-arrow-left {
        left: 1.25em;
    }

    .imagelightbox-arrow-right {
        right: 1.25em;
    }
}

@media only screen and (max-width: 320px) {
    .imagelightbox-arrow-left {
        left: 0;
    }

    .imagelightbox-arrow-right {
        right: 0;
    }
}

@media (max-width: 767px) {
    .block.type-7 {
        .filter-nav li {
            margin-bottom: 10px;
        }

        .filter-content a {
            display: block;

            img {
                margin: 0 auto;
            }
        }

        .icon-wrapper .icon-entry {
            margin-bottom: 30px;
        }
    }
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes imagelightbox-loading {
    0% {
        opacity: .5;
        transform: scale(0.75);
    }

    50% {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: .5;
        transform: scale(0.75);
    }
}

@keyframes imagelightbox-loading {
    0% {
        opacity: .5;
        transform: scale(0.75);
    }

    50% {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: .5;
        transform: scale(0.75);
    }
}

.mobile-link {
    display: none;

    a.submit {
        background: #f8c12c;
        margin: 30px 15px 15px;
        display: block;
        border-radius: 3px;
        padding: 13.5px;
        font-size: 16px;
        text-align: center;
        font-weight: 700;
    }

    .widget-t-search {
        float: none;
        padding: 15px 0 30px;
        margin: 0 15px;
    }

    .widget-t-inner {
        width: 100%;
    }
}

.table-striped > tbody > tr:nth-child(odd) > {
    td, th {
        font-family: Open Sans,sans-serif;
    }
}
