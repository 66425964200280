﻿/* ==============================
  13. Indurial Solution styles
============================== */

.indurial-solution {
    position: relative;
    background: #298fc2;
}

.indpad {
    padding: 1.5em 0;
}

.indurial-solution-text h2 {
    font: 600 2em/1em Open Sans,sans-serif;
    letter-spacing: .4px;
    color: #fff;
    font-style: italic;
    margin: 0;
    padding: .4em 0 0;
    text-align: center;
    display: inline-block;
}

.anim-5, .anim-5-all * {
    transition: all .5s ease;
}

.contactus-button {
    margin: .5em 0 0;

    a {
        background: #19242a;
        display: inline-block;
        font: 500 1em/1em Open Sans,sans-serif;
        margin-top: 1px;
        padding: 0 17px 0 23px;
        text-transform: uppercase;
        float: right;
        border-radius: 5px;
        line-height: 42px;
        letter-spacing: 2px;

        &:hover {
            background: #3d515c;
        }
    }
}
