﻿/* shop page styles */

.shop-item-wrapper {
    margin-left: -10px;
    margin-right: -10px;
}

.single-shop-item {
    margin: 0 -5px 50px;
    position: relative;
    overflow: hidden;

    .img-box {
        overflow: hidden;

        img {
            transition: all .3s ease-out;

            &:hover {
                transform: scale(1.2) translateZ(0);
            }
        }
    }

    .content {
        text-align: center;
        margin-top: 25px;

        h3 {
            font-size: 15px;
            font-weight: 700;
            color: #242424;
            text-transform: uppercase;
            margin: 0 0 10px;
        }

        .review-box i {
            font-size: 13px;
            color: #298fc2;
        }

        .price {
            font-family: PT Sans Narrow;
            font-size: 25px;
            color: #2d2d2d;
            font-style: italic;
            display: block;
        }

        a.thm-btn {
            background: #298fc2;
            border-radius: 5px;
            color: #000;
            text-transform: uppercase;
            line-height: 32px;
            display: inline-block;
            letter-spacing: .05em;
            font-family: Open Sans;
            font-weight: 600;
            padding: 0 18px;
            margin-top: 10px;

            &:hover {
                background: #019bb2;
                color: #fff;
            }
        }
    }
}

.shop-page .page-navigation {
    margin-top: 0;
    text-align: center;
}
