﻿/* ==============================
  17. Team styles
============================== */

.team-pad {
    padding: 0 0 6em;
}

.team-area {
    .our-team {
        margin-top: .6em;
    }

    .our-team-page {
        margin-top: 0;
        margin-bottom: 30px;
    }
}

.team {
    transition: all .3s linear 0s;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    .team-images {
        background: url(../images/team/img-bg.jpg) repeat 50%;
        text-align: center;
        position: relative;
        z-index: 0;
        overflow: hidden;
        height: 297px;

        &:after {
            background: transparent;
            border: 1px solid #fff;
            /*z-index: -1;*/
            top: 10px;
            left: 10px;
            bottom: 10px;
            right: 10px;
            transition: all .3s linear 0s;
            content: "";
            position: absolute;
            display: block;
        }

        &:before {
            content: "";
            position: absolute;
            display: block;
            background-color: rgba(3, 137, 158, 0.6);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: scale(0.2);
            transition: all .3s;
            opacity: 0;
        }

        img {
            max-width: 100%;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .team-content {
        padding: 1em 0 .8em;
        text-align: center;
        cursor: pointer;
        position: relative;
        border: 2px solid #e4e4e4;
        margin: 5px 0 0;

        h4 {
            font: 400 1.15em/1em Open Sans,sans-serif;
            color: #242424;
            letter-spacing: .3px;
            margin: 0;
        }

        p {
            letter-spacing: .3px;
            margin: 0;
            font: 400 14px/22px Open Sans,sans-serif;
            color: #898989;
        }
    }

    .social-icons {
        display: table;
        position: absolute;
        bottom: 15px;
        width: calc(100% - 30px);
        text-align: center;
        transition: all .3s linear 0s;

        li {
            display: inline-block;

            + li {
                margin-left: 25px;
            }

            a {
                font-size: 22px;
                color: #fff;
                padding: 0;

                &:focus, &:hover {
                    background: none;
                    color: #c89551;
                }
            }
        }
    }

    &:hover {
        .social-icons {
            bottom: 125px;
        }

        .team-images {
            &:after {
                border: 1px solid #fff;
                top: 10px;
                left: 10px;
                bottom: 10px;
                right: 10px;
                z-index: 1;
                background: transparent;
            }

            &:before {
                transform: scale(1);
                opacity: 1;
                z-index: 1;
            }
        }

        .team-content {
            background: #e6ab0c;
            transition: all .3s linear 0s;
            border: 2px solid #d59d08;

            h4, p {
                color: #fff;
            }
        }
    }
}

@media (max-width: 991px) {
    .team {
        padding: 15px;
    }
}

@media (max-width: 767px) {
    .team {
        padding: 15px;
        width: 400px;
        max-width: 100%;
        margin: 0 auto;
    }
}

@media (max-width: 1199px) {
    .team .social-icons li + li {
        margin-left: 15px;
    }
}

.dedicated-team {
    padding-top: 22px;

    .our-teams {
        margin-bottom: 30px;
    }
}

.team-wrapper {
    padding: 100px 0;
    min-height: 669px;
}

.team .box-img {
    position: relative;

    img {
        width: 100%;
    }

    .caption {
        position: absolute;
        bottom: -146px;
        background: rgba(10, 45, 78, 0.8);
        transition: all .3s ease;
        padding: 20px 30px;
    }

    &:hover .caption {
        width: 100%;
        height: 100%;
        bottom: 0;
        padding: 40px 30px;
    }

    .caption {
        h2 {
            font-weight: 700;
            font-size: 18px;
            text-transform: uppercase;
            color: #fff;
            margin: 0 0 20px;
        }

        p {
            color: #fff;
            margin: 0 0 20px;
            font-size: 15px;
        }

        a {
            font-size: 13px;
            color: #298fc2;
            text-transform: uppercase;
            font-weight: 700;
        }
    }
}

.team-wrapper4 {
    background: url(../images/team/bg-team4.jpg) repeat;
    padding: 101px 0 71px;
    min-height: 813px;
    width: 100%;

    .team {
        margin: 0 0 30px;
    }
}
