﻿/* Blog list */
.blog-item {

    @include media-breakpoint-up(lg) {
        .single-service-page & {
            min-height: 530px;
        }
    }

    .published-date {
        font-size: 11px;
        font-weight: 600;
        color: $medium;
        text-transform: uppercase;
        margin-top: 0.5rem
    }

    h3 {
        font-size: 16px;
        margin-top: 1rem;

        a {
            color: $dark;
        }
    }

    p {
        font-size: 14px;
        line-height: 1.5;
        margin-top: 1rem;
    }

    .read-more {
        font-weight: 600;
        margin-top: 0.5rem;
        font-size: 13px;
    }
}

/* Taxonomy list */
.taxonomy-list {
    li {
        padding: 0 3rem 0.25rem !important;
        margin: 0.6rem 0.5rem;
        border: $border;

        a {
            font-family: $open-sans-cond;
            font-size: 15px;
            color: $medium;
            font-weight: 600;
        }
    }
}

/* Blog Detail */
.social-share {
    .fa {
        &::before {
            display: inline-block;
            padding: 10px 0;
            width: 37px;
            text-align: center;
        }
    }
}