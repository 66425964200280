﻿/* ==============================
  33. Control in compliance
============================== */

.ind-common-pad2 {
    padding-top: 5em;
    padding-bottom: 6.5em;
}

.quality-wrapper {
    position: relative;

    h2 {
        font: 300 2.8em/1.3em Open Sans,sans-serif;
        margin: 0 0 1.2em;
        color: #242424;
        padding: 0;
    }

    p {
        padding: 0;
        color: #6c6c6c;
        margin: 0 0 4em;
        font: 400 1.2em/1.8em Open Sans,sans-serif;
    }
}

.vision-wrapper {
    position: relative;

    ul {
        padding: 0;

        li {
            padding: 0 4em;
            display: inline-block;
            text-align: center;
            border-right: 1px solid #cfcfcf;

            p {
                color: #6c6c6c;
                font: 700 1.6em/1.8em Open Sans,sans-serif;
                padding: 0;
                text-transform: uppercase;
            }

            &:last-child {
                border-right: 1px solid transparent;
            }

            a {
                color: #6c6c6c;

                &:hover {
                    color: #298fc2;
                }
            }
        }
    }
}

/* ==============================
  34. Control in compliance
============================== */

.fluid-service-area-home {
    display: flex;
    overflow: hidden;

    .work-image-ser {
        width: calc(50% - 115px);
        float: left;
        text-align: right;
        display: block;
        position: relative;
    }

    .service-promo {
        width: calc(50% + 115px);
        float: left;
        position: relative;
        background: url(../images/services/ser-bg1.jpg) repeat;
        padding-left: 30px;
    }
}

@media (max-width: 991px) {
    .fluid-service-area-home .work-image-ser {
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 991px) {
    .fluid-service-area-home .work-image-ser img {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .fluid-service-area-home .service-promo {
        width: 100%;
        padding-bottom: 30px;
        padding: 15px;
    }
}

.test-quote-sec {
    margin-left: -8em;
    position: relative;
}

.testimonial-t-sec {
    position: relative;

    .testimonialn-slider {
        img {
            width: 130px;
            /* border: 2px solid #FFFFFF; */
            border-radius: 50%;
            display: inline-block;
        }

        .content p {
            color: #bbc1c7;
            margin: 1em 0 0;
            padding: 0;
            font: 400 1.1em/1.6em Open Sans,sans-serif;
        }

        .sign img {
            width: 160px;
            display: inline-block;
            border-radius: 0;
        }

        .name-content {
            display: block;
            width: 100%;

            .tst-img {
                float: left;
                margin: 0 1.5em 0 0;
            }

            .client-name {
                float: left;
                margin: 1em 0 0;

                p {
                    font: 600 1.3em/2em Open Sans,sans-serif;
                    margin: 0;
                    padding: 0;
                    color: #fff;

                    span {
                        display: block;
                        color: #fff;
                        font: 400 .79em/1.8em Open Sans,sans-serif;
                    }
                }

                ul li {
                    display: inline-block;
                    margin: 8px -1px 0 1px;

                    a {
                        color: #53565a;
                        font-size: 1em;
                        margin: 0 .4em 0 0;
                    }
                }
            }
        }
    }
}

.service-t-content {
    /*position: relative;
  padding: 3em 20em 4em 7em;*/
    max-width: 670px;
    margin: 0;
    z-index: 2;
    position: relative;
    padding: 36px 10px 0 130px;
}

.testimonial-t-sec .testimonialn-slider .owl-dots .owl-dot {
    span {
        border: 2px solid #637c93;
        background: transparent;
        width: 12px;
        height: 12px;
    }

    &.active span {
        border: 2px solid #53565a;
        background-color: #53565a;
    }
}

.owl-theme .owl-dots .owl-dot:hover span {
    border: 2px solid #53565a;
    background-color: #53565a;
}

.testimonialn-slider.owl-theme .owl-controls {
    text-align: left;
    margin-top: 0;
}

.featured-services {
    overflow: hidden;
    background: #0b3357;

    .left-full-img img {
        max-width: none;
    }
}
