﻿/* ==============================
  36. About us and Frequently Ask questions
============================== */

.about-sec-content {
    position: relative;

    h4 {
        color: #303030;
        font: 400 1.3em/1.5em Open Sans,sans-serif;
        margin: .8em 0 1em;
        padding: 0;
    }

    p {
        padding: 0;
        color: #898989;
        font: 400 1.1em/1.7em Open Sans,sans-serif;
        margin: 0 0 1em;
    }

    ul li {
        color: #6c6c6c;
        font: 400 1.1em/2em Open Sans,sans-serif;

        .fa {
            color: #233859;
            margin-right: 5px;
            font-size: 1em;
        }
    }
}

.accordian-area-pad {
    margin-top: 1.42em;
}

.accordian-area {
    position: relative;

    .panel .panel-heading .panel-title a {
        i {
            font-size: .8em;
            line-height: 50px;
            position: absolute;
            top: 0;
            left: 0;
        }

        .fa-plus {
            display: none;
        }

        &.collapsed .fa-plus {
            display: block;
            width: 25px;
            height: 25px;
            text-align: center;
            font-size: 10px;
            border-radius: 50%;
            border: 1px solid #c8c8c8;
            color: #0e1322;
        }

        i {
            margin: 12.5px;
            line-height: 25px;
        }

        .fa-minus {
            display: block;
            width: 25px;
            text-align: center;
            color: #000;
            height: 25px;
            font-size: 10px;
            border-radius: 50%;
            border: 1px solid #184064;
            background: #fff;
        }

        &.collapsed .fa-minus {
            display: none;
        }
    }

    .panel-group .panel {
        border-radius: 0;
        background: transparent;
        margin: 10px 0 0;
    }

    .panel {
        border-radius: 0;
    }

    .panel-default > .panel-heading {
        position: relative;
        padding: 0;
        margin: 0;
    }

    .panel-title {
        font: 400 18px Open Sans,sans-serif;
        background: #fff;

        &.on {
            background: #184064;
        }
    }

    .panel .panel-heading .panel-title a {
        background: #184064;
        display: block;
        color: #fff;
        padding: 12px 0;
        margin: 0 0 0 50px;
        height: 50px;

        &.collapsed {
            background: #fff;
            color: #242424;
        }
    }

    .panel-body {
        background: #fff;
        color: #898989;
        font: 400 1.1em/1.8em Open Sans,sans-serif;
    }
}

.panel-title.actives {
    height: 50px;
}

.section_header3 h2 {
    color: #fff;
}

.latest-news1 {
    background: url(../images/latest-news/news-bg.jpg) no-repeat scroll center 0;
    background-size: cover;
}

.about-but {
    position: relative;
    display: inline-block;
    float: left;
    margin-left: 0;
    padding-top: 4em;

    a {
        background: #000;
        border: 1px solid #000;

        i {
            margin-left: 10px;
        }
    }
}

.about-us4 {
    background: url(../images/about/bg-about-us4.jpg) 100% no-repeat;
    padding: 89px 0;
    min-height: 694px;
    background-size: cover;
}

.about-us5 {
    background: url(../images/about/bg-about-us5.jpg) 0 no-repeat;
    padding: 78px 0 58px;
    background-size: cover;
    min-height: 603px;
}

.about-us4 .section-faq, .about-us5 .section-faq {
    padding: 0 0 0 65px;
}

/* ==============================
  40. about-us-section 
============================== */

.about-us-section {

    .sec-title h2 {
        font-size: 28px;
        font-family: PT Sans Narrow,sans-serif;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0 0 40px;
    }

    .about-us {
        padding: 0 100px 0 0;

        img {
            width: 100%;
        }

        ul li:before {
            content: "\f0a9";
            margin-right: 11px;
            font-family: FontAwesome;
            color: #233859;
        }

        p {
            margin: 32px 0;
        }

        ul li {
            font-size: 14px;
            line-height: 32px;
            color: #6c6c6c;
        }
    }

    .faq-left-box .single-faq-left:last-child {
        margin-bottom: 0;
    }

    .about-us .thm-button {
        margin: 40px 0 0;
    }
}

.faq-left-box .single-faq-left {
    margin-bottom: 42px;

    .icon-box, .text-box {
        display: table-cell;
        vertical-align: top;
        padding: 10px 0 0;
    }

    .icon-box {
        font-size: 44px;
        width: 45px;
        color: #2e78cc;
    }

    .text-box {
        padding: 0 0 0 20px;

        h3, p {
            margin: 0;
        }

        h3 {
            text-transform: uppercase;
            font-size: 18px;
            font-family: PT Sans Narrow,sans-serif;
            font-weight: 700;
            color: #333340;
            line-height: 30px;
        }

        p {
            color: #898989;
            font-size: 15px;
            line-height: 26px;
            margin-top: 9px;
        }
    }
}

.faq-sec .right-box {
    padding: 40px 0 0;
}
