﻿#content {
    overflow: hidden;
}

.sectpad {
    padding: 5.7em 0;
}

.gutter-zero {
    .sf_colsIn {
        position: relative;
        padding: 0;
    }
}

.thm-container {
    max-width: 1170px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

footer.row, header.row, section.row {
    position: relative;
}

.top-two-right {
    position: relative;
    overflow: hidden;
    padding: 3.5px 0;

    .top-panel {
        float: right;
        width: 65%;
    }
}