﻿body {
    font-family: $font-family-base;
    font-size: 16px;
    line-height: $line-height-base;
    color: $body-color;
    height: 100%;
    overflow-x: hidden;
}


a {
    &:focus, &:hover {
        text-decoration: none;
        outline: none;
    }
}

.btn, a, button {
    text-decoration: none;
    outline: none;
    transition: all .3s linear 0s;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: $headings-line-height;
    font-weight: $headings-font-weight;
    margin-bottom: 15px;
}

h2 {

    .section_header1 & {
        font-family: $open-sans-cond;
        font-size: 32px;
        color: #000;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: .5px;
        margin: 0 0 30px 0;
    }

    .section_header &, .section_header2 & {
        position: relative;
        font-family: $open-sans-cond;
        font-size: 26px;
        font-weight: 700;
        color: #000;
        text-transform: uppercase;
        letter-spacing: .5px;
        margin: 0 0 30px 0;
        padding-bottom: 15px;

        &::before {
            content: "";
            width: 55px;
            height: 3px;
            display: inline-block;
            background: $secondary;
            position: absolute;
            bottom: 0;
        }
    }

    .color & {
        color: #242424 !important;
    }
}


.sec-title {

    h2, h3 {

        &:after, span {
            display: block;
        }

        &:before {
            content: "";
            width: 55px;
            height: 3px;
            background: $secondary;
            margin-top: 13px;
        }
    }
}