﻿/* faq page styles */

.faq-page .sec-title {
    h2 {
        margin: 0;
    }

    p {
        margin-top: 20px;
    }
}

.faq-form {
    background: #f9f9f9;
    padding: 20px 30px;
    border: 1px solid #eaeaea;
    margin-bottom: 50px;
    margin-top: 30px;

    input {
        height: 44px;
        border: 1px solid #e9e9e9;
        outline: none;
        padding-left: 25px;
        width: 79%;
    }

    .thm-btn, input {
        position: relative;
        top: 1px;
    }

    .thm-btn {
        padding: 0 41px;
        line-height: 44px;
        border-radius: 0;
        margin-left: -4px;
        border: none;
        background: #1fb5c7;
    }
}

.faq-page .accrodion {
    .accrodion-title h4 {
        text-transform: none;
        font-weight: 400;
        color: #8e8d8d;
    }

    &.active .accrodion-title {
        background: #1fb5c7;

        &:after, h4 {
            color: #fff;
        }

        &:after {
            border-color: #fff;
        }
    }
}

.faq-question-one .single-faq {
    margin-bottom: 28px;

    &:last-child {
        margin-bottom: 0;
    }

    h3 {
        font-size: 16px;
        line-height: 33px;
        font-weight: 700;
        color: #242424;
        font-family: Open Sans,sans-serif;
    }

    p {
        color: #898989;
        margin-top: 4px;
    }
}
