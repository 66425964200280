﻿/* ==============================
  Home styles
============================== */

/* Hero Image */
.carousel-inner {

    .video-overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        background: rgba(10, 43, 77, 0.4);
        /* background: url(https://cdnjs.cloudflare.com/ajax/libs/vegas/2.3.1/overlays/03.png);
        opacity: 0.3;*/
    }

    video {
        position: absolute;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 13%;

        @include media-breakpoint-up(md) {
            left: 40%;
        }

        @include media-breakpoint-up(lg) {
            left: 50%;
        }
    }
}

/* Featured Technologies Block */
.featured-technologies-block {

    .item {
        border: 1px solid #dddcdc;
        border-radius: 10px;
        box-shadow: 0 0 1px 0 #b9b9b9;
        background-color: #FFF;
        text-align: center;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }

    .post-content {
        display: inline-block;
        padding: 10px 25px 25px;
    }

    h3 {
        a {
            color: #000;
            font-family: PT Sans Narrow,sans-serif;
            font-size: 26px;
            color: black;
            font-weight: 600;
            text-transform: none;
        }
    }

    .read-more {
        font-size: 14px;
        color: $secondary;
        font-weight: 600;
        text-transform: uppercase;
        background: transparent;
    }
}

/* Market We Serve Block */
$market-we-server-guttter: 45px;
.market-we-serve-block {
    margin-left: -$market-we-server-guttter;
    margin-right: -$market-we-server-guttter;

    > .col-md-4 {
        padding-left: $market-we-server-guttter;
        padding-right: $market-we-server-guttter;
    }

    h3 {
        a {
            font-family: PT Sans Narrow,sans-serif;
            font-size: 22px;
            font-weight: 600;
            color: $secondary
        }
    }

    .item {
        text-align: center;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }

    .post-content {
        padding-bottom: 25px;
    }

    .read-more {
        @extend .btn;
    }
}

/* Who Are We */
.home-who-we-are {
    a.image-callout {
        .image-callout-content {
            background-color: rgba(39, 39, 39, 0.4);
            transition: background-color 0.15s linear;
        }

        h3 {
            text-transform: uppercase;
        }

        &:hover {
            .image-callout-content {
                background-color: transparent;
            }
        }
    }
}

/* Latest Blog Block */
.latest-posts-block {
    background-color: white;
    padding: 25px;
    max-width: 420px;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
        max-width: none;
        margin: 0 -25px;
    }

    .blog-item {
        padding-bottom: 60px;

        img {
            width: 100%;
        }
    }

    a.read-more {
        position: absolute;
        bottom: 15px;
        left: 15px;
        right: 0;
    }
}