﻿/*  Our Projects  */

.our-project {
    background: #ecebef;
    overflow: hidden;
}

.project_txt_btn {
    width: 100%;
    float: left;
    position: absolute;
    left: 0;
    overflow: hidden;
    top: 0;
    text-align: center;
    height: 100%;
}

a.view-all.slide_learn_btn.view_project_btn {
    width: 147px;
    font-size: 14px;
    display: inline-block;
    top: -140px;
    position: relative;
    z-index: 999;
    transition: transform .55s;
    line-height: 43px;
    font-family: Open Sans,sans-serif;
    background: #53cff5;
    text-transform: uppercase;
    border-radius: 3px;
    font-weight: 700;
    color: #fff;
}

.project_txt_btn h6 {
    width: 100%;
    float: left;
    font-size: 16px;
    text-align: center;
    background: #f8c12c;
    color: #000;
    line-height: 50px;
    bottom: -60px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
}

.desi-bounce-to-right {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px transparent;
    backface-visibility: hidden;
    position: relative;
    transition-property: color;
    transition-duration: .5s;
}

.project_img {
    background: #000;

    &:hover img {
        opacity: .5;
        transform: scale3d(1.15, 1.15, 1);
    }

    h6 {
        transition: transform .35s;
    }

    img {
        transition: transform .35s;
        backface-visibility: hidden;
        border: 0;
    }

    &:hover img {
        transform: translate3d(0, -30px, 0);
        border: 0;
    }

    .view_project_btn, h6 {
        position: absolute;
        z-index: 99;
    }

    &:hover {
        h6 {
            bottom: 0;
            left: 0;
        }

        .view_project_btn {
            opacity: 1;
        }

        a.view-all.slide_learn_btn.view_project_btn {
            top: 40%;
        }
    }
}

/* ==============================
  9. Our Project Slider styles
============================== */

.our-pro-slider {
    .pro-sliders {
        padding-top: 8px;

        .post-image {
            position: relative;
            overflow: hidden;

            img {
                max-width: 100%;
            }
        }

        .item {
            position: relative;

            .four_arrow_hover_box {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            &:focus .four_arrow_hover_box, &:hover .four_arrow_hover_box {
                opacity: .9;
                transform: scaleX(1);
            }
        }

        .pro-web {
            position: absolute;
            bottom: 1.5em;
            z-index: 6;
            width: 100%;
            text-align: center;

            h4 {
                color: #fff;
                text-transform: uppercase;
                font: 600 1.3em/1em Open Sans,sans-serif;
                transition: all .3s linear 0s;
                margin: 0;
            }
        }
    }

    .owl-nav {
        position: absolute;
        right: 0;
        top: -85px;
    }
}

@media (max-width: 599px) {
    .our-pro-slider .pro-sliders .item {
        max-width: 320px;
        margin: 0 auto;
    }
}

.pro-sliders.owl-theme .owl-controls .owl-nav [class*=owl-] {
    background: transparent;
    border-radius: 0;
    margin: 0;
    padding: 0;
}

.fullwidth-silder2 .owl-controls .owl-nav {
    .owl-next, .owl-prev {
        width: 40px;
        text-align: center;
        font-size: 22px;
        line-height: 36px;
        color: #8d8d8d;
        transition: all .3s linear 0s;
        height: 40px;
        padding: 0;
        border: 1px solid #8d8d8d;
        border-radius: 0;
        background: transparent;
    }
}

.fullwidth-silder3 .owl-controls .owl-nav {
    .owl-next, .owl-prev {
        width: 40px;
        text-align: center;
        font-size: 22px;
        line-height: 36px;
        color: #8d8d8d;
        transition: all .3s linear 0s;
        height: 40px;
        padding: 0;
        border: 1px solid #8d8d8d;
        border-radius: 0;
        background: transparent;
    }
}

.pro-sliders {
    &.owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
        background: #329cad;
        color: #fff;
        text-decoration: none;
    }

    &.owl-carousel .owl-controls .owl-nav .owl-prev {
        margin-right: 5px;
    }
}


/* ==============================
  37. Our Projects
============================== */

.our-galler-htwo {
    background: url(../images/our-projects/bg.jpg) no-repeat top;
    position: relative;
}

.our-galler-three {
    background: #ebebeb no-repeat top;
    position: relative;
    padding: 100px 0 0;
}

.our-galler-four {
    background: url(../images/projects/bg-our-project4.jpg) no-repeat top;
}

.our-galler-five, .our-galler-four {
    position: relative;
    padding: 96px 0 0;
}

.our-galler-five {
    background: #1e2228 no-repeat top;
}

.fullwidth-slider {
    padding: 0 1.5em;

    &.owl-theme .owl-controls .owl-nav [class*=owl-] {
        background: transparent;
        border-radius: 0;
        margin: 0;
        padding: 0;
    }

    &.owl-carousel .owl-controls .owl-nav {
        .owl-next {
            background: #53565a;
            width: 33px;
            text-align: center;
            font-size: 20px;
            line-height: 30px;
            color: #000;
            transition: all .3s linear 0s;
            height: 33px;
        }

        .owl-prev {
            background: #53565a;
            width: 33px;
            text-align: center;
            font-size: 20px;
            line-height: 30px;
            color: #000;
            transition: all .3s linear 0s;
            height: 33px;
            margin-right: 20px;
        }
    }

    &.owl-theme .owl-controls {
        margin-top: 4em;

        .owl-nav [class*=owl-]:hover {
            background: #eaac05;
            color: #000;
            text-decoration: none;
        }
    }
}

.fullwidth-silder {
    padding-top: 2em;
}

.fullwidth-silder2 .owl-theme .owl-controls, .fullwidth-silder3 .owl-theme .owl-controls {
    margin-top: 0;
}

.fullwidth-slider2 .owl-nav, .fullwidth-slider3 .owl-nav {
    position: absolute;
    top: -92px;
    width: 1170px;
    text-align: right;
    margin: 0 auto;
    left: 0;
    right: 0;
}
