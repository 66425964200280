﻿/* HC_Block_HomeMarkets */

.service-info {
    height: 100%;

    .item {
        position: relative;
        height: 100%;
        padding-bottom: 30px;
        margin-bottom: 30px;

        .align-self-stretch & {
            padding-bottom: 62px;
        }
    }
    // single column styles (image float right)
    .sectpad .col-md-12 > & {

        .item {
            @include make-row();
            padding-bottom: 40px;
            margin-bottom: 45px;
            border-bottom: 1px solid $soft;
        }

        .col {
            @include make-col-ready();

            @include media-breakpoint-up(md) {
                @include make-col(6)
            }
        }

        .post-image {
            margin-bottom: 15px;
            border-radius: $border-radius;

            img {
                border-radius: $border-radius;
            }

            @include media-breakpoint-up(md) {
                order: 2;
            }
        }

        .post-content {
            order: 1;

            h3 {
                margin-top: 0;
            }
        }
    }

    h3 {
        a {
            font-size: 22px;
            color: black;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    .read-more {
        @extend .btn;

        .align-self-stretch & {
            max-width: 150px;
            position: absolute;
            bottom: 30px;
            left: 50%;
            margin-left: -75px;
        }
    }
}

/* Image Hover Link */

.image-callout {
    .image-callout-content {
        position: absolute;
        display: flex;
        top: 0;
        width: 100%;
        height: 100%;
        color: white;
        text-align: center;
        z-index: 99;

        h3 {
            width: 100%;
            vertical-align: middle;
            font-size: 20px;
            font-weight: 600;
            color: white;
            letter-spacing: 1px;
            padding: 0 38px;

            .home-who-are-we {
                text-transform: uppercase;
            }
        }
    }

    .callout-content-wrapper {
        width: 100%;
        display: flex;
        align-items: center;

        .hover-slide-in-up & {
            animation-name: calloutSlideDown;
        }
    }

    p {
        padding: 0 75px;
        line-height: 1.7;
        animation-name: fadeOut;
    }

    .hover-slide-in-up & {

        .callout-content-wrapper, p {
            animation-duration: 1s;
            animation-fill-mode: both;
        }

        &:hover {

            .image-callout-content {
                background-color: rgba($secondary, 0.5);
            }

            .callout-content-wrapper {
                animation-name: calloutSlideUp;
            }

            p {
                display: block;
                animation-name: fadeIn;
                animation-direction: alternate;
            }
        }
    }
}

/* Full Width Image With Content */
.full-width-image-content {
    .container {
        padding-top: 50px;
        padding-bottom: 110px;
    }
}

/* popular product widget */
.single-sidebar-widget .best-seller.popular-post .content {
    a h4 {
        font-size: 14px;
        text-transform: uppercase;
        color: #242424;
        margin: 0;
        font-family: Open Sans;
    }

    p {
        font-size: 16px;
        color: #2d2d2d;
        margin: 10px 0 15px;
    }

    a.read-more {
        font-size: 13px;
        text-transform: uppercase;
        color: #1cbac8;
        font-weight: 700;
        font-family: PT Sans Narrow,sans-serif;
        letter-spacing: .03em;
    }
}
