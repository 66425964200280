﻿/* ==============================
  20. Side Bar Widget styles
============================== */

%sidebar-item {
    background: $soft;
    border-left: 5px solid $soft;
    color: $navy;
    font-weight: 700;
    text-transform: uppercase;
    display: block;
    margin-bottom: 10px;
    padding: 12px 20px;
    transition: all .3s ease;
    position: relative;
}

.parent:after {
    content: '\f101';
    font-family: 'FontAwesome';
    position: absolute;
    top: 0;
    right: 20px;
    line-height: 50px;
    color: #6A6969;
    font-weight: normal;
}

.pull-left.news .single-sidebar-widget {
    padding-right: 39px;
}

.pull-right .single-sidebar-widget {
    padding-left: 0;
}

.single-side-left {
    width: 320px;
    margin: 0 50px 0 0;
    float: left;
}

.content-right {
    width: 800px;
}

.single-sidebar-widget {
    margin-bottom: 50px;

    &:last-child {
        margin-bottom: 0;
    }

    .contact-form {
        input {
            height: 40px;
        }

        .select-menu + .ui-selectmenu-button {
            height: 40px;
            margin-bottom: 2px;

            .ui-selectmenu-text {
                line-height: 40px;
            }
        }
    }

    .dwnld-broucher a {
        text-transform: uppercase;
        color: #08add0;
        font-weight: 600;
        margin-top: 30px;
        font-size: 12px;
        display: inline-block;
    }

    .special-links {

        li {
            &:last-child {
                margin-bottom: 0;

                > a {
                    padding-right: 0;
                }
            }

            a {
                @extend %sidebar-item;
            }

            &.active a, &:hover a {
                background: $secondary;
                color: #fff;
                border-left: 5px solid $navy;
            }

            &.active a:after, &:hover a:after {
                color: #fff;
            }

            a:after {
                font-family: FontAwesome;
                position: absolute;
                top: 0;
                right: 20px;
                color: #6a6969;
                font-weight: 400;
            }
        }

        ul.nav > li {

            &:last-child > a {
                padding-right: 0;
            }

            > ul.submenu {
                top: 100%;
                left: 0;

                > li {

                    > ul.submenu {
                        top: 0;
                        left: 100%;

                        &.align-right {
                            right: 100%;
                            left: auto;
                        }
                    }

                    &:hover > ul.submenu {
                        visibility: visible;
                        opacity: 1;
                    }
                }

                &.align-right {
                    right: 0;
                    left: auto;
                }
            }

            &:hover > ul.submenu {
                visibility: visible;
                opacity: 1;
            }

            ul.submenu {
                top: 0;
                left: 100%;
                position: absolute;
                width: 275px;
                opacity: 0;
                visibility: hidden;
                z-index: 99999;
                -webkit-transition: all .3s ease;
                transition: all .3s ease;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
                -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);
                -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16);

                li {
                    display: block;
                    padding: 0;
                    background: #fff;

                    &:last-child a {
                        border-bottom: 0;

                        &:after {
                            display: none;
                        }
                    }

                    &:hover > a {
                        color: #242424;
                        background: #51c5e9;
                    }

                    a {
                        margin-bottom: 0px;
                        color: #fff;
                        font: 600 14px 'Open Sans', sans-serif;
                        padding: 15px 25px 15px;
                        position: relative;
                        display: block;
                        font-weight: 600;
                        -webkit-transition: all .3s ease;
                        transition: all .3s ease;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}

.search-box placeholder {
    color: #000;
}

.single-sidebar-widget {
    .search-box form {
        background: #e5e5e5;
        width: 100%;
        height: 48px;
        border: 1px solid #e9e9e9;

        input {
            padding-left: 30px;
            border: none;
            outline: none;
            height: 100%;
            color: rgba(255, 255, 255, 0.5);
            width: calc(100% - 55px);
            background-color: transparent;

            &::-webkit-input-placeholder {
                /* WebKit, Blink, Edge */
                color: rgba(255, 255, 255, 0.5);
                opacity: 1;
            }

            &:-moz-placeholder {
                /* Mozilla Firefox 4 to 18 */
                color: rgba(255, 255, 255, 0.5);
                opacity: 1;
            }

            &::-moz-placeholder {
                /* Mozilla Firefox 19+ */
                color: rgba(255, 255, 255, 0.5);
                opacity: 1;
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: rgba(255, 255, 255, 0.5);
            }
        }

        button {
            position: relative;
            left: 2px;
            width: 50px;
            background: #298fc2;
            color: #000;
            font-size: 16px;
            padding: 11px 0;
            border: none;
            outline: none;
            transition: all .3s ease;

            &:hover {
                background: #1fb5c7;

                i {
                    color: #fff;
                }

                &:after {
                    background: #1fb5c7;
                }
            }

            i {
                position: relative;
                z-index: 2;
                transition: all .3s ease;
            }
        }
    }

    .categories ul li {
        &:last-child a {
            border: none;
        }

        a {
            color: #898989;
            text-transform: capitalize;
            line-height: 42px;
            display: block;
            border-bottom: 1px solid #e6e6e6;
            position: relative;

            &:after {
                content: "\f105";
                font-family: FontAwesome;
                color: #c8c8c8;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    .popular-post {
        .img-cap-effect .img-box .img-caption:before {
            border-width: 2px;
        }

        li {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .content {
            display: table-cell;
            vertical-align: middle;
        }

        .img-box {
            display: table-cell;
            vertical-align: middle;
            width: 110px;
        }

        .content {
            padding-left: 30px;

            a h4 {
                font-family: Open Sans,sans-serif;
                color: #242424;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 500;
                transition: all .3s ease;

                &:hover {
                    color: #1fb5c7;
                }
            }

            p {
                color: #8e8d8d;
                line-height: 20px;
                margin: 0;
                font-size: 13px;
            }

            span {
                font-size: 13px;
                color: #1cbac8;
                display: block;
                margin-top: 4px;
                font-weight: 500;
            }
        }
    }

    .text-box p {
        color: #8e8d8d;
    }

    .insta-feed {
        font-size: 0;
        display: inline-block;

        ul {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            .img-cap-effect .img-box .img-caption:before {
                border-width: 2px;
            }
        }

        img {
            max-width: 100%;
            display: inline-block;
            font-size: 0;
            margin-bottom: 1px;
            margin-right: 1px;
        }
    }

    .tag-cloud {
        font-size: 0;
        margin-left: -5px;
        margin-right: -5px;

        li {
            display: inline-block;

            a {
                display: block;
                text-transform: uppercase;
                border: 1px solid #eaeaea;
                border-radius: 20px;
                padding: 7px 22px;
                font-size: 12px;
                margin: 0 5px 10px;
                transition: all .3s ease;

                &:hover {
                    background: #e5e5e5;
                    border-color: #e5e5e5;
                }
            }
        }
    }
}

.single-service-contact {
    border: 2px solid #e9e9e9;
    padding: 25px;
    background: url(../images/services/bg-envelope.jpg) 100% 100% no-repeat;

    h3 {
        font-family: PT Sans Narrow,sans-serif;
        font-size: 25px;
        color: #3e3e3e;
        font-weight: 600;
        margin: 0 0 20px;
    }

    p {
        font-size: 15px;
        color: #5b5b5b;
        margin: 0 0 25px;
    }

    a {
        font-family: PT Sans Narrow,sans-serif;
        font-size: 14px;
        font-weight: 700;
        color: #298fc2;
        text-transform: uppercase;

        i {
            margin: 0 0 0 10px;
        }
    }
}

.single-service-pdf {
    background-color: $soft;

    a {
        @extend %sidebar-item;
    }
}

.single-client-box {
    margin-bottom: 50px;

    &:last-child {
        margin-bottom: 0;
    }

    .content {
        display: table-cell;
        vertical-align: middle;
    }

    .icon-box {
        display: table-cell;
        vertical-align: middle;
        width: 170px;
        text-align: center;
        border-top: 4px solid #1fb5c7;
        border-left: 1px solid #e9e9e9;
    }

    .content, .icon-box {
        border-bottom: 1px solid #e9e9e9;
    }

    .content {
        padding: 30px;
        border-right: 1px solid #e9e9e9;
        border-top: 1px solid #e9e9e9;

        h3 {
            font-size: 18px;
            font-weight: 700;
            font-family: PT Sans Narrow,sans-serif;
            color: #242424;
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        p {
            color: #898989;
            margin: 0;
        }
    }
}

.single-sidebar-widget ul.brochure-btns li {
    display: block;
    margin-bottom: 21px;

    a {
        font-family: Open Sans,sans-serif;
        color: #404040;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 15px;
        display: block;
        background: #fbfbfb;
        border-bottom: 2px solid #ededed;
        padding: 12px 20px;

        i {
            color: #e94429;
            margin: 0 1em;
            font-size: 1.2em;
        }
    }
}

.single-post-page .image-caption-box .text-box h2, .single-service-page .image-caption-box .text-box h2 {
    color: #242424;
    font-size: 25px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 5px;
    font-family: PT Sans Narrow,sans-serif;
}
