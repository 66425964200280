﻿/* ==============================
  38. Lattest news
============================== */

.event-pad {
    padding-top: 1.3em;
}

.news-wrapper {
    position: relative;
    background: #f4f4f4;
}

.news-evn-img {
    position: relative;
    overflow: hidden;

    &:before {
        content: "";
        position: absolute;
        bottom: -3px;
        width: 10px;
        height: 3px;
        display: inline-block;
        background: #fff;
        margin: 0;
    }

    .event-date {
        position: absolute;
        width: 62px;
        height: 68px;
        text-align: center;
        background: #53565a;
        left: 10px;
        bottom: 0;

        h3 {
            font: 600 1.6em/1.2em Open Sans,sans-serif;
            margin: 0;
            padding: .4em 0 0;

            small {
                font: 400 .59em/1em Open Sans,sans-serif;
                display: block;
            }
        }
    }
}

.news-evn-cont {
    position: relative;

    h3 {
        /*font: 600 1.07em/1.5em 'PT Sans Narrow', sans-serif;*/
        font-family: PT Sans,sans-serif;
        /*letter-spacing: 0.5px;*/
        color: #000;
        transition: all .3s linear 0s;
        padding: 0 0 .5em;
        margin: 0;
    }

    p {
        margin: 0;
        font: 400 1em/1.5em Open Sans,sans-serif;
        color: #8e8d8d;
        padding: 0;
    }
}

.news-meta {
    padding: 1.8em 0 1em;

    a {
        font: 400 1.07em/1em "PT Serif",sans-serif;
        color: #a6a6a6;
        padding-right: 13px;
        font-style: italic;

        + a:before {
            content: "";
            background: #a6a6a6;
            margin-right: 15px;
            width: 1px;
            height: 14px;
            bottom: -3px;
            position: relative;
            display: inline-block;
        }
    }
}

.sectpad-sec {
    padding-top: 2.7em;
    padding-bottom: 5.7em;
}
