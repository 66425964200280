﻿/* ==============================
  3. header top styles
============================== */

#header {
    position: relative;
}

.wel-band-bg {
    padding: 14px 0;

    p, a {
        font-size: 15px;
        font-weight: 600;
        color: #6282A0;
        margin: 0;
    }

    a {
        padding: 0 6px;
    }

    .wel-band-links {
        margin-left: 13px;

        a {
            position: relative;
            padding: 0 15px;

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                margin-top: -4px;
                left: -3px;
                width: 2px;
                height: 11px;
                background-color: #c6c6c6;
            }
        }
    }

    .fa {
        font-size: 22px;
        line-height: 13px;
    }
}

.header-3 {
    .wel-t-band {
        background: #083661;
    }

    .wel-band-bg {
        background: url(../images/header-top-band/head-top-band3.jpg) no-repeat 50%;
        background-size: cover;
    }

    .widget-t-search {
        padding: 0;

        .widget-t-inner {
            margin-top: 0;
            border: none;
        }
    }

    .widget-t .search-form .form-control {
        height: 63px;
    }

    .nav-t-holder .nav-t-footer ul.nav > li {
        &.active a, &:hover a {
            color: #298fc2;
        }

        ul.submenu li a {
            color: #242424;
        }
    }
}

.header-5 {
    background: #0f263a;

    .navi {
        border-top: 1px solid #1b364c;
    }

    .media-body p {
        color: #9e9e9e;
    }

    .touch_top ul .item:last-child {
        border-right: 1px solid #485662;
    }
}

.touch_top {
    float: left;
    padding: 8px 0;
    width: 100%;

    ul {
        .item {
            padding: 0;
            float: right;

            &:last-child {
                border-right: 1px solid #d8d8d8;
            }
        }

        .item-ad {
            width: 50%;
            padding: 0;
        }

        .item-phone {
            width: 46%;
            padding: 0 0 0 4%;
        }

        .item .media .media-left a i {
            font-size: 2em;
            color: #1fa6c8;
        }
    }
}

.media-body {
    text-align: left;

    p {
        text-align: left;
        color: #232932;
        padding: 0;
        margin: 0;
        font: 400 1.072em/1.4em Open Sans,sans-serif;

        span {
            font-size: 1em;
            line-height: 1.7em;

            a:hover {
                color: #298fc2;
            }
        }
    }
}

.touch_top .nav {
    margin: 0;
}

.widget-search {
    float: right;
    padding: 8px 0;
}

.widget .search-form .form-control {
    height: 37px;
    border-radius: 0;
    border: none;
    font: 1em/1em Open Sans,sans-serif;
    padding: 0 15px;
    box-shadow: none;
    color: silver;
    z-index: 0;
}

.widget-inner {
    border: 1px solid #ddd;
}

.widget .search-form .form-control + .input-group-addon {
    padding: 0;
    border: 0;
    border-radius: 0;
    background-color: transparent;

    button {
        border: 0;
        background: #fff;
        color: #ddd;
        font-size: 1.2em;
        line-height: 2.1em;
        padding-right: 15px;
    }
}

/*************** Search variation 4 style ****************/

.search_overlay {
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background: #eef0f3;
    position: fixed;
    top: 0;
    left: 0;

    &.show {
        opacity: 1;
        visibility: visible;
        z-index: 9999999;
    }

    .close_search {
        background: url(../images/home_page_variation4/close_popup.png) no-repeat;
        width: 23px;
        height: 20px;
        position: absolute;
        right: 15px;
        top: 10px;
    }
}

.search_main_container {
    max-width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -100px auto 0 -300px;
    width: 100%;

    h4 {
        font-size: 25px;
        color: #242424;
        margin-bottom: 30px;
    }

    form {
        position: relative;
        width: 100%;
        float: left;
    }

    .search_lightbox_input {
        width: 100%;
        float: left;
        border-bottom: 1px solid #242424 !important;
        border: 0;
        font-size: 18px;
        color: #242424;
        background: transparent;
        padding: 5px 0;
    }
}

.search_lightbox_input {
    &::-webkit-input-placeholder {
        color: #242424;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color: #242424;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color: #242424;
    }

    &:-ms-input-placeholder {
        color: #242424;
    }
}

.search_overlay button[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 35px;
    border: none;
    background: none;
}

/*************** End Search variation 4 style ****************/