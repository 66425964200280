﻿/* ==============================
  Banner styles
============================== */

.carousel-inner {

    .carousel-caption {
        top: 10%;
        bottom: auto;

        @include media-breakpoint-up(md) {
            left: 23% !important;
            right: 23% !important;
        }
    }

    .item {
        min-height: 638px;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-position: 50%;
        background-color: #357eac;
    }

    h1 {
        font-weight: 700;
        letter-spacing: 3.5px;
        margin-bottom: 20px;


        &.bnrfnt {

            &50 {
                font-size: 50px;
            }

            &40 {
                font-size: 40px;
            }
        }
    }

    p {
        font-size: 20px;
        margin-bottom: 30px;
    }
}
.carousel-control {
    background: none;
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 40px;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    visibility: hidden !important; // disabled
    opacity: .65;
    margin-top: -20px;
    transition: all .5s ease;
    top: 50%;
    border-radius: 0;
    z-index: 88;

    &.left {
        left: 1%;
    }

    &.right {
        right: 1%;
    }

    &:hover {
        opacity: 1;
        background: #000;
    }
}
