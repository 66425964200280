﻿/* ==============================
  23. News page styles
============================== */

.widget-search-blog {
    width: 80%;
    position: relative;
    display: inline-block;
    margin: 0 0 40px 1em;

    .search-form .form-control {
        height: 45px;
        border-radius: 0;
        border: none;
        font: 1em/1em Open Sans,sans-serif;
        padding: 0 30px;
        box-shadow: none;
        color: silver;
        z-index: 0;

        + .input-group-addon {
            padding: 0 1px 0 8px;
            border: 0;
            border-radius: 0;
            background-color: #298fc2;

            button {
                border: 0;
                background: #298fc2;
                color: #fff;
                font-size: 1.2em;
                line-height: 2.1em;
                padding-right: 15px;
            }
        }
    }
}

.single-blog-post {
    .meta-info {
        padding: 1.3em 0;
        border-bottom: 1px solid #e6e6e6;

        .content-box .post-links li {
            display: table-cell;
            vertical-align: middle;
        }
    }

    .date-box {
        position: absolute;
        width: 70px;
        height: 75px;
        top: 2em;
        border: 1px solid #696969;
        left: 1.5em;
        background: #fff;
        text-align: center;
    }

    .inner-box {
        b {
            font-size: 2em;
            font-style: italic;
            font-weight: 300;
            border-bottom: 1px solid #696969;
            padding: 0 0 .2em;
            display: block;
        }

        span {
            display: block;
            font-size: 1em;
            background: #298fc2;
            color: #fff;
            padding: .2em 0 .3em;
        }
    }

    .meta-info .content-box .post-links li a {
        color: #a1a1a1;
        padding-right: 30px;
        transition: all .3s ease;

        &:hover i {
            color: #1fb5c7;
        }

        i {
            font-size: 16px;
            margin-right: 10px;
            transition: all .3s ease;
        }

        &:hover, i {
            color: #298fc2;
        }
    }

    a.read-more {
        background: #53565a;
        color: #fff;
        border-radius: 4px;
        font: 600 1.08em/1em PT Sans Narrow,sans-serif;
        padding: .8em 1.6em;
        display: inline-block;
        border: 1px solid #53565a;

        &:hover {
            background: #298fc2;
            border: 1px solid #298fc2;
        }
    }
}

.single-post-wrapper {
    position: relative;
}

.single-blog-post {
    margin-bottom: 50px;

    h3 {
        color: #010101;
        font-weight: 600;
        text-transform: none;
        font-size: 20px;
        margin: .6em 0 0;
        padding: 0 0 .8em;
    }

    h6 {
        color: #1cbac8;
        font: 400 1em/1em Open Sans,sans-serif;
        margin: 2em 0 0;
        padding: 0;
    }

    .img-box {
        position: relative;
        overflow: hidden;
    }
}

/* ==============================
  25. News Details styles
============================== */

.news p {
    font: 400 14px/26px Open Sans, sans-serif;
}

.post-list {
    margin: 0;
    width: 100%;

    li {
        float: left;
        width: 270px;
        font: 400 14px/30px Open Sans,sans-serif;
        color: #ff8c04;
        letter-spacing: .3px;

        &:before {
            content: "\f105";
            font: normal normal normal 14px/1 FontAwesome;
            font-size: 12px;
            text-rendering: auto;
            padding-right: 10px;
        }
    }
}

blockquote {
    padding: 30px;
    border: none;
    background: #f5f4f4;
    margin-top: 27px;

    &:before {
        float: left;
        content: "\f10d";
        display: inline-block;
        font: normal normal normal 55px/1 FontAwesome;
        text-rendering: auto;
        width: 70px;
        color: #d0d0d0;
    }

    p {
        float: left;
        font: 400 16px/26px Open Sans,sans-serif;
        letter-spacing: .3px;
        color: #898989;
        width: calc(100% - 70px);
        margin: 5px 0 0;
        padding-left: 14px;
    }

    footer {
        text-align: right;
        font: 300 14px/26px PT Sans Narrow,sans-serif;
        font-style: italic;
    }
}

@media (max-width: 479px) {
    blockquote {
        padding: 5px;
    }
}

@media (max-width: 1199px) {
    blockquote:before {
        font-size: 30px;
        width: 40px;
    }
}

@media (max-width: 1199px) {
    blockquote p {
        margin: 0;
    }
}

/* ==============================
  26. News Details Comments
============================== */

.leave-comments .leave-comment-title, .news-comments .comments_count {
    margin: 30px 0;
    font: 600 1.5em/1em PT Sans Narrow,sans-serif;
    color: #242424;
    text-transform: uppercase;
}

@media (max-width: 400px) {
    .comments_count, .leave-comment-title {
        margin-left: 20px;
    }
}

.comments {
    margin: 0 0 30px;
    border: 1px solid #eee;

    .comment {
        padding: 20px 20px 25px;
        margin: 0;
    }

    .comment-bg {
        background: #fcfcfc;
    }

    .comment {
        + .comment {
            margin-top: 0;
            border-top: 1px solid #eee;
        }

        .media-left {
            padding: 8px 30px 0 5px;

            a {
                display: block;
                width: 70px;
                height: 70px;

                img {
                    max-width: 100%;
                }
            }
        }

        .media-body {
            h5 {
                font: 500 1em/2em Open Sans,sans-serif;
                margin: 0;
                color: #242424;
            }

            p {
                font: 400 14px/30px Open Sans,sans-serif;
                color: #898989;
            }

            .date-reply {
                margin: 9px 0;

                li {
                    float: left;

                    a {
                        padding: 0;
                        font: 13px/1 Open Sans,sans-serif;
                        text-transform: capitalize;
                        color: #47bcca;

                        &:focus, &:hover {
                            background: none;
                        }
                    }

                    + li {
                        color: #bc945e;

                        &:before {
                            content: "";
                            width: 1px;
                            height: 13px;
                            float: left;
                            background: #47bcca;
                            margin: 0 10px;
                        }

                        a {
                            float: left;
                            color: #f6ba18;
                        }
                    }
                }
            }
        }

        &.reply {
            padding-left: 0;
            margin: 0;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}

@media (max-width: 400px) {
    .comments .comment {
        margin: 0 5px 0 15px;
        text-align: center;
    }
}

@media (max-width: 479px) {
    .comments .comment .media-left {
        padding-right: 0;
        padding-bottom: 20px;
        display: block;
    }
}

@media (max-width: 991px) {
    .comments .comment .media-body {
        overflow: visible;
    }
}

@media (max-width: 400px) {
    .comments .comment .media-body .date-reply li {
        display: inline-block;
        text-align: center;
        float: none;
    }
}

@media (max-width: 991px) {
    .comments .comment.reply {
        margin-left: -70px;
    }
}

@media (max-width: 479px) {
    .comments .comment.reply {
        margin-left: 30px;
    }
}

@media (max-width: 400px) {
    .comments .comment.reply {
        margin-left: 0;
    }
}

.comment_form {
    padding: 30px;
    border: 1px solid #eee;
    margin: 0;
    background: #fff;

    .leave-comment-title {
        margin: 0 0 26px;
        font: 700 20px/28px PT Sans Narrow,sans-serif;
        color: #353535;
    }

    .form-control {
        border-radius: 0;
        padding: 9px 20px;
        font: 14px/25px Open Sans,sans-serif;
        color: #000;
        margin-bottom: 20px;
        background: #f9f9f9;
        border: 1px solid #ebebeb;
        box-shadow: none;

        &::-moz-placeholder {
            color: #c4c4c4;
            opacity: 1;
        }

        &:-ms-input-placeholder, &::-webkit-input-placeholder {
            color: #c4c4c4;
        }
    }

    input {
        width: 48%;
        float: left;
        height: 42px;

        + input {
            margin-left: 26px;
        }
    }

    textarea {
        height: 188px;
        resize: none;
        margin-bottom: 32px;
    }

    .btn-primary {
        background: #f6ba18;
        border: 1px solid #f6ba18;
        text-transform: uppercase;
        letter-spacing: 1px;
        font: 600 1.2em/2.1em Open Sans,sans-serif;
        padding: 7px 22px;
        color: #19242a;
        margin-top: 10px;
        border-radius: 5px;
        letter-spacing: 3px;

        &:focus, &:hover {
            color: #000;
            border-radius: 5px;
            border-color: #bb793c;
        }
    }
}

@media (max-width: 479px) {
    .comment_form {
        padding: 20px 15px;
    }
}

@media (max-width: 1199px) {
    .comment_form input {
        width: 47%;
    }
}

@media (max-width: 991px) {
    .comment_form input {
        width: 46%;
    }
}

@media (max-width: 767px) {
    .comment_form input {
        width: 100%;
    }
}

@media (max-width: 479px) {
    .comment_form input {
        width: 100%;
    }
}

@media (max-width: 1199px) {
    .comment_form input + input {
        margin-left: 31px;
    }
}

@media (max-width: 767px) {
    .comment_form input + input {
        margin-left: 0;
    }
}

@media (max-width: 479px) {
    .comment_form input + input {
        margin-left: 0;
    }
}