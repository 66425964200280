﻿/* ==============================
  28. Testimonials page styles
============================== */

.sectpad-t {
    padding: 6em 0 0;
}

.testimonial-parea {
    padding: 0 0 6em;
}

.testimonials p {
    font: 400 14px/26px Open Sans,sans-serif;
    letter-spacing: .3px;
    color: #898989;
    padding-top: 25px;
    margin: 0;
}

.testimonial-col-p {
    padding: 5em 1em 1em;
    border-bottom: 1px solid #e4e4e4;
}

.testimonial-p {
    .media-body {
        h3 {
            font: 400 1.2em/1em Open Sans,sans-serif;
            color: #242424;
            margin: 0 0 .8em;
            padding: 0;
            letter-spacing: .3px;
        }

        p {
            letter-spacing: .3px;
            font: 400 1em/1.8em Open Sans,sans-serif;
            color: #898989;
            margin-bottom: 1em;
        }

        a {
            font: 600 14px/26px Open Sans,sans-serif;
            letter-spacing: .3px;
            color: #1fb5c7;
            font-style: italic;
            text-align: left;
            display: block;
        }

        h6 {
            display: block;
            text-align: right;
        }
    }

    .media-left {
        padding-right: 60px;

        a img {
            width: 140px;
            height: 153px;
        }
    }
}

@media (max-width: 479px) {
    .testimonial-p {
        .media-left {
            display: block;
            text-align: center;
            padding-right: 0;
        }

        .media-body h3 {
            padding: 1em 0 0;
        }
    }
}

.section_header-t {
    position: relative;
    padding: 0;

    h2 {
        font: 700 2.2em/1.2em PT Sans Narrow,sans-serif;
        color: #000;
        text-transform: uppercase;
        position: relative;
        padding-bottom: 20px;
        letter-spacing: .3px;
        margin: 0;

        &:before {
            content: "";
            width: 55px;
            height: 3px;
            display: inline-block;
            background: #298fc2;
            position: absolute;
            bottom: 0;
        }
    }
}


/* ==============================
  42. Testimonial Page
============================== */

.testimonial-wrapper3 {
    background: url(../images/testimonial/testimonial-bg2.jpg) bottom no-repeat fixed;
    background-size: cover;

    .testimonial-sec {
        .testimonial-slider .owl-dots .owl-dot span {
            border: 2px solid #fff;
        }

        &.active span, .owl-theme .owl-dots .owl-dot:hover span {
            border: 2px solid #ffa10d;
        }
    }
}

.testimonial-wrapper2 {
    background: url(../images/testimonial/testimonial-bg3.jpg) bottom no-repeat fixed;
    background-size: cover;

    .testimonial-sec .testimonial-slider .owl-dots .owl-dot {
        span {
            border: 2px solid #fff;
        }

        &.active span {
            border: 2px solid #ffa10d;
        }
    }
}

.testimonial-wrapper3 .testimonial-sec .owl-theme .owl-dots .owl-dot:hover span {
    border: 2px solid #ffa10d;
}