﻿section.newsletter {
    margin: 0 0 100px;

    .inn {
        background: url(../images/home_page_variation5/bg-newsletter.jpg) top no-repeat #f0f0ef;
        min-height: 142px;
        padding: 23px 40px;

        h2 {
            font: 700 2em/1em PT Sans Narrow,sans-serif;
            color: #000;
            text-transform: uppercase;
            position: relative;
            padding-bottom: 20px;
            letter-spacing: .3px;
            margin: 0;
        }

        input[type=text] {
            width: 100%;
            height: 45px;
        }

        p {
            color: #8e8d8d;
            font-size: 15px;
            margin: 0;
        }
    }

    .req-button {
        margin: 0;

        a {
            line-height: 44px;
            background: #298fc2;
            padding: 0 20px;
            border: 1px solid #298fc2;

            i {
                padding: 0 0 0 10px;
            }
        }
    }
}
