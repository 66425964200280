﻿/* ==============================
  31. Home Page 2 styles
============================== */

.wel-t-band {
    position: relative;
    background: #f0f2f3;
    font: 400 1em/1em Open Sans,sans-serif;
    color: #6e6e6e;

    p span {
        color: #3a3a3a;
    }
}

.req-button {
    position: relative;
    float: right;
    margin-left: 1em;

    a {
        background: #53565a;
        font: 600 1.08em/1em PT Sans Narrow,sans-serif;
        display: inline-block;
        padding: 1em 1.52em;
        border-radius: 4px;
        border: 1px solid #53565a;
        margin: 0;

        &:hover {
            border-radius: 4px;
            border: 1px solid #53cff5;
            background: #53cff5;
        }
    }

    .submit {
        &:before {
            background: #53cff5;
        }

        &:focus, &:hover {
            color: #fff;
        }

        &:focus {
            border-color: #53cff5;
        }
    }
}


/* ==============================
  32. Home Slider
============================== */

.thm-banner-t-wrapper {
    .caption-h1 {
        font-size: 40px;
        line-height: 50px;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        position: relative;
    }

    .caption-p {
        font-size: 20px;
        font-style: italic;
        color: #fff;
        line-height: 28px;
    }

    .caption-divider span.divider {
        width: 1px;
        height: 90px;
        background: #fff;
        display: block;
    }

    .inverse {
        background: #f7be3d;
        border: 2px solid #f7be3d;
        font: 300 1.2em/1em Open Sans,sans-serif;

        &:hover {
            background: #1fb5c7;
            border: 2px solid #1fb5c7;
        }
    }

    .thm-btn {
        transition: all 0.3s ease !important;
        font-weight: 700 !important;
        padding: 1.1em 2.5em;
        border-radius: 4px;
        font: 400 1.2em/1em Open Sans,sans-serif;

        i {
            margin: 0;
            border-color: transparent;
        }
    }

    .inversen {
        background: #53cff5;
        border: 2px solid #53cff5;
        font: 300 1.2em/1em Open Sans,sans-serif;

        &:hover {
            background: #f7be3d;
            border: 2px solid #f7be3d;
        }
    }

    .thm-btn i:after {
        display: none;
    }
}

.thm-banner-wrapper {
    .thm-btn.inverse i {
        color: #fff;
    }

    .brder {
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 100px;
        height: 4px;
        background: #e6ab0c;
        margin-top: 22px;
    }

    .brder-left {
        display: block;
        width: 100px;
        height: 4px;
        background: #e6ab0c;
        margin-top: 22px;
        text-align: left;
        margin-left: 0;
    }
}
