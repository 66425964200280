﻿/* ==============================
  45. We Customer Support
============================== */

.we-customer-support {
    display: flex;
    overflow: hidden;

    .work-image-ser {
        width: calc(50% - 115px);
        float: left;
        text-align: right;
        display: block;
        position: relative;
    }

    .service-promo {
        width: calc(50% + 115px);
        float: left;
        position: relative;
        padding-left: 0;
        background: url(../images/support/ser-bg1.jpg) repeat;
    }

    .service-t-content {
        max-width: 650px;
        margin: 0;
        z-index: 2;
        position: relative;
        padding: 78px 0 78px 110px;

        form input[type=text] {
            background: #0d2840;
            color: #3a78b6;
            font-size: 15px;
            height: 50px;
            width: 100%;
            padding: 0 20px;
            margin: 0 0 15px;
            border: 1px solid #254766;
            border-radius: 5px;
        }

        p {
            font: 300 20px Open Sans,sans-serif;
            color: #fff;
            margin: 0 0 25px;
        }
    }
}

@media (max-width: 991px) {
    .we-customer-support {
        .work-image-ser {
            width: 100%;
            text-align: center;
        }

        .service-promo {
            padding: 15px;
            width: 100%;
        }
    }
}
